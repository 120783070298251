import { UILabelsInterface } from '@/interfaces';

export default {
	labels: (state: UILabelsInterface) => ({
		classifications: state.classifications,
		defaultEmailLabelId: state.defaultEmailLabelId,
		refreshAfterUtc: state.refreshAfterUtc,
		isLoading: state.isLoading,
		err: state.err
	})
};
