import { OfficeProxy, RECIPIENT_TYPE } from '@egress/officejs-proxy';
import hash from 'object-hash';
import { CapturedMatchStructure } from '@common/interfaces';
import performanceLogger from '@common/performanceLogger';

function addRecipients(
	hashSource: { [key: string]: any },
	propertyName: string,
	recipients: { emailAddress : string }[]
): void {
	if (!recipients || !recipients.length) return;
	const ordered = recipients.map((r) => r.emailAddress).sort();
	hashSource[propertyName] = ordered; // eslint-disable-line no-param-reassign
}

export default async function getMessageHash(
	restId: string,
	keywords: string[] = [],
	capturedMatches: CapturedMatchStructure[] = []
): Promise<string> {
	const timer = performanceLogger.startNew('utils.getMessageHash');
	const hashSource = {
		restId,
		keywords,
		capturedMatches
	};

	const officeProxy = new OfficeProxy();

	addRecipients(hashSource, 'to', await officeProxy.getItemRecipientsAsync(RECIPIENT_TYPE.to));
	addRecipients(hashSource, 'cc', await officeProxy.getItemRecipientsAsync(RECIPIENT_TYPE.cc));
	addRecipients(hashSource, 'bcc', await officeProxy.getItemRecipientsAsync(RECIPIENT_TYPE.bcc));

	timer.stop();
	/**
	 * Getting hashed value for object potentially give different value for same object
	 * due to both objects failing equlity check (i.e. A !== B)
	 * but hashed value is always consistent for same string variables
	 */
	return hash(JSON.stringify(hashSource));
}
