/**
 * Asynchronously wait for a given condition to be met
 * @param conditionFunction The condition to wait for
 */
function until(conditionFunction: () => boolean): Promise<any> {
	const poll: any = (resolve: () => void) => {
		if (conditionFunction()) resolve();
		else {
			setTimeout(() => poll(resolve), 500);
		}
	};

	return new Promise(poll);
}

/**
 * Asynchronously wait for a given number of milliseconds.
 * @param msToWait Number of milliseconds to wait
 */
async function waitNMilliseconds(msToWait: number): Promise<void> {
	const timeNow = new Date().getTime();
	const timeInOneSec = timeNow + msToWait;
	await until(() => new Date().getTime() >= timeInOneSec);
}

export { waitNMilliseconds, until };
