import { logout, login } from '@common/httpClient/auth/client';
import { addSeconds } from 'date-fns';
import { storeEsiToken } from '@common/token';
import { setUserState } from '@common/utils/clientState';
import { logger, LogEventIds } from '@common/logger';
import { AuthenticationInterface, StoreInterface } from '@/interfaces';
import {
	LOGIN_REQUESTED,
	LOGIN_SUCCESS,
	LOGIN_FAILED,
	LOGOUT_REQUESTED
} from '@/store/types';

const failedPopulationEsiOrClientState = LogEventIds.hasStorage + 1;

export default {
	async [LOGIN_REQUESTED]({ commit, dispatch }: any, credentials: any): Promise<void> {
		try {
			commit(LOGIN_REQUESTED);
			const response = await login(credentials);
			const { data, expiresInSeconds } = response.token;
			const { user } = response;
			const utcDate = new Date().toISOString();
			const expiresAtUtc = addSeconds(new Date(utcDate), expiresInSeconds).toISOString();
			await dispatch(LOGIN_SUCCESS, {
				data,
				expiresAtUtc,
				user
			});
		} catch (err) {
			commit(LOGIN_FAILED, (err as any).localizationId);
			throw err;
		}
	},
	async [LOGIN_SUCCESS]({ commit }: any, payload: any): Promise<void> {
		const { data, expiresAtUtc, user } = payload;
		const results = await Promise.allSettled([
			storeEsiToken(data, expiresAtUtc),
			setUserState(user)
		]);

		if (results.some((t) => t.status === 'rejected')) {
			logger.warn({
				message: 'Failed to populate esi token or user state after login success.',
				properties: {
					source: 'auth.action.ts', method: 'LOGIN_SUCCESS', user: user?.switchId
				}
			}, failedPopulationEsiOrClientState);
		}

		commit(LOGIN_SUCCESS, {
			data,
			expiresAtUtc,
			user
		});
	},
	async [LOGOUT_REQUESTED]({ commit, state, rootState }
		: {commit: any, state: AuthenticationInterface, rootState: StoreInterface}): Promise<void> {
		const { switchId } = rootState.user;
		const { token } = state;

		commit(LOGOUT_REQUESTED);
		await logout(switchId, token);
	}
};
