<script lang='ts'>
import Vue from 'vue';

export default Vue.extend({
	name: 'Eds-Table',
	props: {
		id: {
			type: String,
			required: true
		},
		items: {
			type: Array,
			required: true
		}
	},
	computed: {
		getFirstItem(): Object {
			return this.items[0] as Object;
		},
		getItemKeys() : string[] {
			return Object.keys(this.getFirstItem);
		},
		getItems(): any[] {
			return this.items;
		}
	},
	methods: {
		validateProps(): void {
			if (!(this.items && this.items.length)) {
				throw new Error('\'items\' property must exist and not be null.');
			}
		},
		getValuesForItem(item: Object) : string[] {
			return Object.values(item);
		}
	},
	mounted(): void {
		this.validateProps();
	}
});
</script>

<template>
  <table
    class="eds-table"
    :id="id">
    <thead>
      <tr>
        <th v-for="key in this.getItemKeys" :key="key">
          {{ key }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, rowIndex) in this.getItems"
        :key="rowIndex">
        <td
          v-for="(value, colIndex) in getValuesForItem(item)"
          :key="colIndex + value">
          {{ value }}
        </td>
      </tr>
    </tbody>
  </table>

</template>

<style lang="scss" scoped>
@import 'src/assets/eds/variables.scss';

.eds-table{
	tbody tr td {
		word-wrap: break-word;
		max-width: 15rem;
	}
}

div#app.theme-dark {
	.eds-table
	{
		thead tr th {
			font-weight: bold;
		}
		thead tr th, tbody tr th, tbody tr td {
			background: #2C2D2D;
		}
		tbody tr:nth-child(odd) td {
			background: #383838;
		}
	}
}
</style>
