<template>
  <div>
    <button
      :id="id"
      :type="type"
      class="button--basic"
      :class="[edsButtonType, edsButtonSize]"
      :disabled="disabled"
      :data-attributes="JSON.stringify(dataAttributes)"
      @click="$emit('click', $event)">
      <slot />
    </button>
  </div>
</template>

<script lang='ts'>
import Vue from 'vue';

export default Vue.extend({
	name: 'Eds-Button',
	props: {
		id: {
			required: true,
			type: String
		},
		type: {
			type: String,
			default: 'button',
			validator(value: string) {
				return ['button', 'submit', 'reset'].indexOf(value) !== -1;
			}
		},
		buttonType: {
			type: String,
			default: 'prime',
			validator(value: string) {
				return ['prime', 'secondary', 'warning', 'success'].indexOf(value.toLowerCase()) !== -1;
			}
		},
		buttonSize: {
			type: String,
			default: 'auto',
			validator(value: string) {
				return ['auto', 'full', 'mini'].indexOf(value.toLowerCase()) !== -1;
			}
		},
		dataAttributes: {
			type: Object,
			default: () => {}
		},

		disabled: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		normalizedButtonType(): string {
			return this.buttonType.trim().toLowerCase();
		},
		normalizedButtonSize(): string {
			return this.buttonSize.trim().toLowerCase();
		},
		edsButtonType(): string {
			switch (this.normalizedButtonType) {
			case 'secondary':
				return 'button--secondary';
			case 'warning':
				return 'button--warning';
			case 'success':
				return 'button--success';
			default:
				return 'button--prime';
			}
		},
		edsButtonSize(): string {
			switch (this.normalizedButtonSize) {
			case 'full':
				return 'button--full';
			case 'mini':
				return 'button--mini';
			default:
				return 'button--auto';
			}
		}
	}
});
</script>

<style lang="scss" scoped>
@import '../../assets/eds/variables.scss';

div#app.theme-dark {
	.button--prime {
		background: $prime-3;
		color: white;
		border: $prime-3;
	}

	.button--prime:hover {
		box-shadow: 0 3px 6px #00000029;
		color: white;
	}

	.button--prime:active {
		background: #2C1965;
	}

	.button--prime[disabled], .button--prime:disabled {
		background: #242424;
		border: 1px solid #242424;
		color:#8B8B8B;
	}

	.button--secondary {
		background: #444545;
		color: white;
		border: none;
	}

	.button--secondary:hover {
		box-shadow: 0 3px 6px #00000029;
		color: white;
	}

	.button--secondary:active {
		background: #2D2E2E;
	}

	.button--secondary[disabled], .button--secondary:disabled {
		background: #242424;
		border: 1px solid #242424;
		color: #E4E6EE;
	}
}
</style>
