<script lang='ts'>
import Vue from 'vue';
import { theme } from '@common/enums';
import MessagePrompt from '@/views/dlpPrompts/MessagePrompt.vue';
import RequestTagPrompt from '@/views/dlpPrompts/RequestTagPrompt.vue';
import RequestLabelPrompt from '@/views/dlpPrompts/RequestLabelPrompt.vue';

export default Vue.extend({
	name: 'PromptApp',
	data() {
		return {
			darkModeEnabled: false
		};
	},
	components: {
		MessagePrompt,
		RequestTagPrompt,
		RequestLabelPrompt
	},
	methods: {
		setUserTheme() {
			const urlParams = new URLSearchParams(window.location.hash);
			this.darkModeEnabled = (urlParams.get('theme') === theme.Dark);
		}
	},
	computed: {
		isMessagePrompt(): any {
			return window.location.toString().includes('/prompts/message');
		},
		isRequestLabelPrompt(): any {
			return window.location.toString().includes('/prompts/requestLabel');
		},
		isRequestTagPrompt(): any {
			return window.location.toString().includes('/prompts/requestTag');
		},
		ComponentToRender(): any {
			if (this.isMessagePrompt) {
				return MessagePrompt;
			}
			if (this.isRequestTagPrompt) {
				return RequestTagPrompt;
			}
			if (this.isRequestLabelPrompt) {
				return RequestLabelPrompt;
			}

			// default
			return MessagePrompt;
		},
		ComponentProps(): any {
			const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
			if (this.isMessagePrompt) {
				return {
					msg: urlParams.get('msg'),
					theme: urlParams.get('theme'),
					matches: urlParams.get('matches'),
					countdown: urlParams.get('countdown'),
					appVersion: urlParams.get('appversion')
				};
			}
			if (this.isRequestTagPrompt) {
				return {
					rtds: urlParams.get('rtds'),
					theme: urlParams.get('theme'),
					countdown: urlParams.get('countdown')
				};
			}
			if (this.isRequestLabelPrompt) {
				return {
					rlds: urlParams.get('rlds'),
					theme: urlParams.get('theme'),
					countdown: urlParams.get('countdown'),
					userLabels: urlParams.get('userlabels')
				};
			}

			// only prompt call this top level component so should not get here
			return {};
		}
	},
	mounted() {
		this.setUserTheme();
	}
});
</script>

<template>
  <div
    id="app"
    :class="{ 'theme-dark': darkModeEnabled, 'theme-light': !darkModeEnabled }">
    <transition
      name="fade"
      mode="out-in">
      <component :is="ComponentToRender" v-bind="ComponentProps" />
    </transition>
  </div>
</template>

<style lang="scss">
html, body {
	font-size: 14px !important;
}

div#app.theme-dark {
	border-top: none;
	margin-top: unset;
}
div#app {
	border-top: 1px solid lightgray;
	margin-top: -1px;
}
</style>
