import { buildGetRequestOption } from '@common/httpClient/buildRequestOptions';
import ApiError from '@common/errors/apiError';
import FetchError from '@common/errors/fetchError';
import { ConfigurationInterface } from '@common/interfaces';

/**
 * Load config for the application
 * @param authToken The authentication token for the current user
 * @returns {Object}
 */
const getConfig = async (): Promise<ConfigurationInterface> => {
	try {
		const response = await fetch('/api/v2/config', buildGetRequestOption());
		const responseData = await response.json();

		// Handle failures
		if (!response.ok) {
			throw new ApiError(
				`Loading config failed. Server returned non-2xx status code. StatusCode:[${response.status}].`,
				responseData?.localizationId,
				responseData?.code,
				responseData?.userEventId
			);
		}

		return responseData;
	} catch (err) {
		if (err instanceof ApiError) {
			throw err;
		}
		throw new FetchError('Fetch request could not be sent', 'api.errors.fetchFailed');
	}
};

export default getConfig;
