import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const rbpModule = {
	state: {
		advice: null,
		isLoading: false,
		error: null,
		opinionContextId: null
	},
	mutations,
	actions,
	getters
};

export default rbpModule;
