class FetchError extends Error {
	localizationId?: string;

	constructor(message: string, localizationId?: string) {
		super(message);
		this.localizationId = localizationId;
	}
}

export default FetchError;
