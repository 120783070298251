import 'core-js/stable/atob';
import AuthError from '@common/errors/authError';
import { addMinutes } from 'date-fns';
import { jwtDecode, JwtPayload } from 'jwt-decode';

export default class TokenDetail {
	name: string;

	data: string;

	expiresAtUtc: string;

	/**
	 * Create an instance of the TokenDetail class
	 * @param {string} tokenName The name of this token
	 * @param {string} tokenData The encoded token data
	 * @param {string | null} expiresAtUtc Override the exp value of the token with a custom expiry
	 */
	constructor(tokenName: string, tokenData: string, expiresAtUtc: string | null = null) {
		this.name = tokenName;
		this.data = tokenData;
		if (!expiresAtUtc) {
			const { exp: tokenProvidedExpiry } = jwtDecode<JwtPayload>(this.data);
			if (tokenProvidedExpiry) {
				this.expiresAtUtc = new Date(tokenProvidedExpiry * 1000).toISOString();
				return;
			}
		}
		const defaultExpiration = jwtDecode<JwtPayload>(this.data)?.exp;
		if (defaultExpiration !== undefined) {
			this.expiresAtUtc = expiresAtUtc ?? new Date(defaultExpiration).toISOString();
			return;
		}

		throw new AuthError('Authentication token invalid', 'No token expiry specified for token');
	}

	expiresWithinMinutes(minutes: number): boolean {
		const dateInNMinutes = addMinutes(new Date(), minutes);
		return dateInNMinutes.getTime() >= new Date(this.expiresAtUtc).getTime();
	}

	hasExpired(): boolean {
		const dtNow = new Date();
		return dtNow.getTime() >= new Date(this.expiresAtUtc).getTime();
	}
}
