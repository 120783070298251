class ValidationError extends Error {
	localizationId?: string;

	errorCode?: string;

	constructor(message: string, localizationId?: string, errorCode?: string) {
		super(message);
		this.localizationId = localizationId;
		this.errorCode = errorCode;
	}
}

export default ValidationError;
