import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const labelsModule = (defaults: any) => ({
	state: {
		classifications: defaults.classifications ?? [],
		defaultEmailLabelId: defaults.defaultEmailLabelId ?? '',
		refreshAfterUtc: defaults.refreshAfterUtc ?? '',
		isLoading: false,
		err: null
	},
	mutations,
	actions,
	getters
});

export default labelsModule;
