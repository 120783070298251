export const CACHED_OPINION_CONTEXT_ID_PROPERTY = 'opinion-context-id';
export const CACHED_OPINION_IDS_PROPERTY = 'opinion-ids';
export const DIALOG_CLOSE = 'DIALOG_CLOSE';
export const DIALOG_LABELSELECTED = 'DIALOG_LABELSELECTED';
export const DIALOG_LABELS_REQUEST = 'DIALOG_LABELS_REQUEST';
export const DIALOG_LABELS_RESPONSE = 'DIALOG_LABELS_RESPONSE';
export const DIALOG_TAGSELECTED = 'DIALOG_TAGSELECTED';
export const DIALOG_SSO_SUCCESS = 'DIALOG_SSO_SUCCESS';
export const DIALOG_LOGIN_SUCCESS = 'DIALOG_LOGIN_SUCCESS';
export const DIALOG_LOGIN_FAILED = 'DIALOG_LOGIN_FAILED';
export const DIALOG_LOGIN_DECLINED = 'DIALOG_LOGIN_DECLINED';
export const DIALOG_SEND_EMAIL = 'DIALOG_SEND_EMAIL';
export const DIALOG_PREVENT_ADVICE = 'DIALOG_PREVENT_ADVICE';
export const DIALOG_PREVENT_ERROR = 'DIALOG_PREVENT_ERROR';
export const DIALOG_OPEN_SSO = 'DIALOG_OPEN_SSO';
export const DIALOG_REDIRECT = 'DIALOG_REDIRECT';
export const REMOVE_RECIPIENT = 'REMOVE_RECIPIENT';
export const REPLACE_RECIPIENT = 'REPLACE_RECIPIENT';
export const MOVE_ALL_RECIPIENTS = 'MOVE_ALL_RECIPIENTS';
export const SET_SWITCH_HEADER = 'SET_SWITCH_HEADER';
export const GET_PREVENT_STATE = 'GET_PREVENT_STATE';
export const RBP_HEADER = 'x-$rbp-opinion';
export const SWITCH_HEADER = 'x-$switch';
export const LOCALSTORAGE_APP_CONFIG_STORE = 'eg-ojs-appconfigstore';
export const CLIENT_STATE = 'eg-ojs-clientstate';
export const ITEM_DATA_STATE = 'eg-ojs-item-data';
export const TOKEN_STORE = 'eg-ojs-tokenstore';
export const LOCALSTORAGE_OPINION_STORE = 'eg-ojs-opinionstore';
export const ORIGINALRECIPIENT_STORE = 'eg-ojs-originalrecipientsstore';
export const ATTACHMENT_STORE = 'eg-ojs-attachmentstore';
export const TOKEN_NAME_GRAPH = 'graph-token';
export const TOKEN_NAME_ESI = 'esi-token';
export const NOTIFICATION_SEND_PROCESSING = 'send-progress';
export const RBP_CLIENT_TYPE = 'OfficeJS';
export const CLIENT_INFO_EVENT_NAME = 'ClientInformation';
export const PERFORMANCE_METRIC_EVENT_NAME = 'PerformanceMetric';
export const MESSAGE_SENT_WITH_ERROR_EVENT_NAME = 'MessageSentWithError';
export const PUT_MESSAGE_REQUEST = 'put-message-request';
export const DIAGNOSTIC_INFO_REQUEST = 'REQUEST_DIAGNOSTIC_INFO';
export const DIAGNOSTIC_INFO_RESPONSE = 'REQUEST_DIAGNOSTIC_RESPONSE';

// ItemTypes relating to email message
// https://learn.microsoft.com/en-us/office/vba/outlook/concepts/forms/item-types-and-message-classes
export const MESSAGE_ITEM_TYPES = ['IPM.Note', 'IPM.Resend'];
