import hash from 'object-hash';
import { CLIENT_STATE } from '@common/constants';
import { ClientStateInterface } from '@common/interfaces';
import IndexedDbRepository from '@common/dbStorage/indexedDbStore';
import { OfficeProxy } from '@egress/officejs-proxy';

/* eslint-disable no-undef */
export default class ClientStateInfoStore {
	store: IndexedDbRepository<ClientStateInterface>;

	/**
	 * Create an instance of the ClientStateInfoStore class
	 * @param {string | null} storeName Provide a name to give this clientStateInfo store.
	 */
	constructor(storeName: string | null = null) {
		this.store = new IndexedDbRepository(storeName ?? 'client-state-info-store');
	}

	/**
	 * @param store The clientState store to avoid re-instantiation
	 * @returns {any | null} the existing clientState objects or null
	 */
	async resolveForBackwardCompatibility(store: any): Promise<any | null> {
		const clientState: ClientStateInterface | null = await store.get(hash(CLIENT_STATE));
		// copy existing clientState for new scoped user
		if (clientState && clientState.auth?.isAuthenticated) {
			await this.updateClientStateInfo(clientState);
			return clientState;
		}

		return null;
	}

	/**
	 * Retrieves the value of the ClientStateInfo with the given hash
	 * @returns {object | StorageError}
	 */
	async getClientStateInfo(): Promise<any | null> {
		const officeProxy = new OfficeProxy();
		const { emailAddress } = officeProxy.getUserProfile();
		const key = `${CLIENT_STATE}-${hash(emailAddress)}`;
		let found = await this.store.get(key);

		// Backwards compatibility (Todo: deleted once new clientState key is wide spread)
		if (!found) {
			found = await this.resolveForBackwardCompatibility(this.store);
		}

		if (!found) return null;
		return found;
	}

	/**
	 * Updates the value of the clientStateInfo with the given hash
	 * @param {object} clientStateInfo The value to update the clientStateInfo with
	 * @returns {void | StorageError}
	 */
	async updateClientStateInfo(
		clientStateInfo: ClientStateInterface | null
	): Promise<void> {
		const officeProxy = new OfficeProxy();
		const { emailAddress } = officeProxy.getUserProfile();
		const key = `${CLIENT_STATE}-${hash(emailAddress)}`;
		await this.store.update(key, clientStateInfo);
	}

	/**
	 * Removes the clientStateInfo with the given hash from the store.
	 * Does nothing if such an clientStateInfo doesn't exist
	 * @returns {void | StorageError}
	 */
	async removeClientStateInfo(): Promise<void> {
		const officeProxy = new OfficeProxy();
		const { emailAddress } = officeProxy.getUserProfile();
		const key = `${CLIENT_STATE}-${hash(emailAddress)}`;
		await this.store.remove(key);
	}

	/**
	 * Drops the instance of the created store.
	 * @returns {void | StorageError}
	 */
	async dropStore(): Promise<void> {
		await this.store.dropStore();
	}
}
