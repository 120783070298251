import { getClassificationLabels } from '@common/httpClient/policies/client';
import { getEsiToken } from '@common/token';
import { setLabels } from '@common/utils/clientState';
import {
	GET_LABELS_FAILED,
	GET_LABELS_REQUESTED,
	GET_LABELS_SUCCESS
} from '@/store/types';

export default {
	async [GET_LABELS_REQUESTED]({ commit }: any) {
		try {
			commit(GET_LABELS_REQUESTED);

			const token = await getEsiToken();
			const labels = await getClassificationLabels(token);
			await setLabels(labels);

			commit(GET_LABELS_SUCCESS, labels);
		} catch (err) {
			commit(GET_LABELS_FAILED, err);
		}
	}
};
