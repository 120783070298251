/* eslint-disable max-classes-per-file */
class RbpError extends Error {
	localizationId?: string;

	errorCode?: string | null;

	userEventId?: string | null;

	code: number;

	constructor(
		message: string,
		localizationId?: string,
		errorCode?: string | null,
		userEventId?: string | null
	) {
		super(message);
		this.localizationId = localizationId;
		this.errorCode = errorCode;
		this.userEventId = userEventId;
		this.code = parseInt(errorCode ?? '0', 10);
	}
}

class RbpForbiddenError extends Error {
	localizationId?: string;

	errorCode?: string | null;

	userEventId?: string | null;

	constructor(
		message: string,
		localizationId?: string,
		errorCode?: string | null,
		userEventId?: string | null
	) {
		super(message);
		this.localizationId = localizationId;
		this.errorCode = errorCode;
		this.userEventId = userEventId;
	}
}
class RbpTimeoutError extends Error {
	localizationId?: string;

	errorCode?: string | null;

	userEventId?: string | null;

	constructor(
		message: string,
		localizationId?: string,
		errorCode?: string | null,
		userEventId?: string | null
	) {
		super(message);
		this.localizationId = localizationId;
		this.errorCode = errorCode;
		this.userEventId = userEventId;
	}
}

export {
	RbpError,
	RbpForbiddenError,
	RbpTimeoutError
};
