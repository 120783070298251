import { AuthenticationInterface } from '@/interfaces';

export default {
	isAuthenticated: (state: AuthenticationInterface) => state.isAuthenticated,
	authUser: (state: AuthenticationInterface) => state.authUser,
	authTokenDetails: (state: AuthenticationInterface) => ({
		data: state.token,
		expiresAtUtc: state.tokenExpiresAtUtc
	})
};
