<script lang='ts'>
import Vue from 'vue';
import { EdsSelectEventOutput } from '@/interfaces';
import { LabelInterface } from '@common/interfaces';

export default Vue.extend({
	name: 'Eds-Select',
	props: {
		id: {
			type: String,
			required: true
		},
		label: {
			type: String,
			required: true
		},
		value: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		hasError: {
			type: Boolean,
			default: false
		},
		displayIcon: {
			type: Boolean,
			default: false
		},
		options: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			selectedValue: ''
		};
	},
	watch: {
		value(newValue) {
			this.selectedValue = newValue;
		}
	},
	mounted(): void {
		this.selectedValue = this.value;
	},
	methods: {
		getSelectedItemInfo(id:string): EdsSelectEventOutput {
			return {
				id,
				description: (this.options as LabelInterface[])
					.find((x) => x?.id === id)?.name ?? ''
			};
		}
	}
});
</script>

<template>
  <fieldset>
    <label class="eds-label" :for="id">{{ label }}</label>
    <div class="eds-select-wrapper">
      <select
        :class="['eds-select', { 'eds-form-error': hasError }]"
        :id="id"
        :name="id"
        :disabled="disabled"
        v-model="selectedValue"
        @input="event => { $emit('input', getSelectedItemInfo(event.target.value)) }"
        @mousedown="event => { $emit('mousedown', event) }"
        @change="event => { $emit('change', getSelectedItemInfo(event.target.value)) }">
        <option
          class="eds-select-option"
          v-for="(option, key) in options"
          :key="key"
          :value="option.id"
          :hidden="option.hidden"
          :selected="option.selected"
          :title="option.description"
          :disabled="option.disabled">
          {{ option.name }}
        </option>
      </select>
    </div>
  </fieldset>
</template>
