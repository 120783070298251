import { theme } from '@common/enums';
import { UIUserInterface } from '@/interfaces';
import {
	LOGIN_SUCCESS,
	LOGOUT_SUCCESS,
	SET_THEME,
	SET_OFFICE_THEME,
	UPDATE_POLICIES_REQUESTED,
	UPDATE_POLICIES_SUCCESS,
	UPDATE_POLICIES_FAILED
} from '@/store/types';

const getDefaultState = () => ({
	auditOptions: 0,
	isPreventClient: false,
	isProtectClient: false,
	largeFileTransferEnabled: false,
	shouldEncryptCompleteMessage: false,
	shouldPerformContentAnalysis: false,
	useMimeBuilder: false,
	displayName: '',
	switchId: '',
	organization: '',
	server: '',
	theme: `${theme.Automatic}`,
	officeTheme: null
});

export default {
	[LOGIN_SUCCESS](state: UIUserInterface, payload: any): void {
		const { user } = payload;
		Object.assign(state, user);
		// eslint-disable-next-line no-underscore-dangle
		if ((this as any)._vm) (this as any)._vm.$logger.setUser(user.switchId);
	},
	[LOGOUT_SUCCESS](state: UIUserInterface): void {
		const defaultState = getDefaultState();
		defaultState.theme = state.theme;
		Object.assign(state, defaultState);
	},
	[SET_THEME](state: UIUserInterface, payload: any): void {
		state.theme = payload;
	},
	[SET_OFFICE_THEME](state: UIUserInterface, payload: any): void {
		state.officeTheme = payload;
	},
	[UPDATE_POLICIES_REQUESTED](state: UIUserInterface): void {
		state.isRefreshingPolicies = true;
	},
	[UPDATE_POLICIES_SUCCESS](state: UIUserInterface, payload: any): void {
		const {
			shouldPerformContentAnalysis,
			isPreventClient, isProtectClient,
			auditOptions, largeFileTransferEnabled,
			shouldEncryptCompleteMessage,
			useMimeBuilder
		} = payload;
		state.auditOptions = auditOptions;
		state.isPreventClient = isPreventClient;
		state.isProtectClient = isProtectClient;
		state.shouldPerformContentAnalysis = shouldPerformContentAnalysis;
		state.largeFileTransferEnabled = largeFileTransferEnabled;
		state.shouldEncryptCompleteMessage = shouldEncryptCompleteMessage;
		state.isRefreshingPolicies = false;
		state.useMimeBuilder = useMimeBuilder;
	},
	[UPDATE_POLICIES_FAILED](state: UIUserInterface): void {
		state.isRefreshingPolicies = false;
	}
};
