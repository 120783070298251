import { buildPostRequestOption } from '@common/httpClient/buildRequestOptions';
import { RbpError, RbpForbiddenError, RbpTimeoutError } from '@common/errors/rbpError';
import AuthError from '@common/errors/authError';
import FetchError from '@common/errors/fetchError';
import performanceLogger from '@common/performanceLogger';
import { OpinionInterface } from '@common/interfaces';
import { getRbpTimeoutMilliseconds } from '@common/token/tokenClaims';

/**
 * Request an opinion from the RBP service
 * @param {Object} getOpinionBody The request body for the opinion call
 * @param {string} authToken The authentication token for the current user
 * @param {string} itemId The ID of the item currently being composed
 * @returns {Object}
 */
async function getOpinion(
	getOpinionBody: object,
	authToken: string,
	itemId: string | number | boolean
): Promise<OpinionInterface> {
	const timer = performanceLogger.startNew('httpClient.rbp.getOpinion');
	const timeoutBuffer = 1000;

	try {
		const rbpTimeout = await getRbpTimeoutMilliseconds();
		const controller = new AbortController();
		const init = buildPostRequestOption(getOpinionBody, authToken, {}, true);
		init.signal = controller.signal;
		const id = setTimeout(() => controller.abort(), rbpTimeout + timeoutBuffer);

		const response = await fetch(
			`/api/v2/rbp/opinion/${encodeURIComponent(itemId)}`,
			init
		);
		clearTimeout(id);

		const responseData = await response.json();

		// Handle failures
		if (!response.ok) {
			switch (response.status) {
				case 400:
					// Not ideal, but localizationId will contain the validation failure reason
					// so just use a default
					throw new RbpError(
						`Requesting RBP opinion failed. ValidationError:[${responseData.localizationId}].`,
						'rbp.generalFailure',
						responseData?.code,
						responseData?.userEventId
					);
				case 401:
					throw new AuthError(
						'Requesting RBP opinion failed. Users session has either expired or is invalid.',
						responseData?.localizationId,
						responseData?.code,
						responseData?.userEventId
					);
				default:
					if (responseData?.code === 6) {
						throw new RbpForbiddenError(
							'Requesting RBP opinion failed. User does not have permission to call Egress Prevent.',
							responseData?.localizationId,
							responseData?.code,
							responseData?.userEventId
						);
					}

					throw new RbpError(
						`Requesting RBP opinion failed. Server returned a non-2xx status code. StatusCode:[${response.status}].`,
						responseData?.localizationId ?? 'rbp.generalFailure',
						responseData?.code,
						responseData?.userEventId
					);
			}
		}

		return responseData;
	} catch (err) {
		if (err instanceof RbpError
			|| err instanceof AuthError
			|| err instanceof RbpForbiddenError
		) {
			throw err;
		} if (err instanceof DOMException) {
			throw new RbpTimeoutError('Requesting RBP opinion failed. Fetch request could not be sent', 'rbp.requestFailed');
		}

		throw new FetchError('Requesting RBP opinion failed. Fetch request could not be sent', 'rbp.requestFailed');
	} finally {
		timer.stop();
	}
}

export default getOpinion;
