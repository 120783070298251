import Vue from 'vue';
import Vuelidate from 'vuelidate';
import { VueLoggerPlugin } from '@egress/client-logger/dist';
import { OfficeProxy } from '@egress/officejs-proxy';
import configureLogger from '@common/logger/configureLogger';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import PromptApp from '@/views/dlpPrompts/PromptApp.vue';
import App from '@/App.vue';
import router from '@/router';
import getVueStore from '@/store';
import AuthMixin from '@/mixins/authMixin';

import '@/assets/scss/app.scss';
import '@/assets/eds/grid.css';
import '@/assets/eds/eds-styles.css';
import i18n from '@/i18n';

library.add(fab, far, fas, fad);
Vue.component('fa-icon', FontAwesomeIcon);

Vue.config.productionTip = false;
let i18nData: any;
i18n.then((value) => {
	i18nData = value;
});

Vue.use(Vuelidate);
Vue.mixin(AuthMixin);
Vue.use(VueLoggerPlugin);
Vue.prototype.$officeProxy = new OfficeProxy();

/* eslint-disable-next-line no-undef */
Office.onReady(async () => {
	if (window.location.toString().includes('/prompts/')) {
		new Vue({
			i18n: i18nData,
			components: { PromptApp },
			render: (h) => h(PromptApp)
		}).$mount('#app');
	} else {
		const store = await getVueStore({ plugins: [] });
		configureLogger(Vue.prototype.$logger, 'taskpane', store.state.user.switchId);
		new Vue({
			router,
			store,
			i18n: i18nData,
			components: { App },
			render: (h) => h(App)
		}).$mount('#app');
	}
});
