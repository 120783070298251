import { UILabelsInterface } from '@/interfaces';
import {
	GET_LABELS_FAILED,
	GET_LABELS_REQUESTED,
	GET_LABELS_SUCCESS,
	LOGOUT_SUCCESS
} from '@/store/types';

const getDefaultState = () => ({
	classifications: [],
	defaultEmailLabelId: '',
	refreshAfterUtc: '',
	isLoading: false,
	err: null
});

export default {
	[GET_LABELS_REQUESTED](state: UILabelsInterface): void {
		state.isLoading = true;
	},
	[GET_LABELS_SUCCESS](state: UILabelsInterface, payload: any): void {
		const { labels, defaultEmailLabelId, refreshAfterUtc } = payload;
		state.classifications = labels;
		state.defaultEmailLabelId = defaultEmailLabelId;
		state.refreshAfterUtc = refreshAfterUtc;
		state.isLoading = false;
		state.err = null;
	},
	[GET_LABELS_FAILED](state: UILabelsInterface, payload: any): void {
		state.isLoading = false;
		state.err = payload;
	},
	[LOGOUT_SUCCESS](state: UILabelsInterface): void {
		Object.assign(state, getDefaultState());
	}
};
