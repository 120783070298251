import Timer from '@common/performanceLogger/timer';
import { logger } from '@common/logger';
import {
	PERFORMANCE_METRIC_EVENT_NAME
} from '@common/constants';

class PerformanceLogger {
	timers: Timer[];

	properties: {};

	constructor() {
		this.timers = [];
		this.properties = { };
	}

	startNew(name: string): Timer {
		const result = Timer.startNew(name);
		this.timers.push(result);
		return result;
	}

	flush(): void {
		const incomplete: Timer[] = [];
		const toSend: Timer[] = [];
		this.timers.forEach((timer) => (timer.completed() ? toSend : incomplete).push(timer));
		this.timers.splice(0, this.timers.length, ...incomplete);

		toSend.forEach((timer) => {
			PerformanceLogger.log(timer, this.properties);
		});
	}

	static log(timer: Timer, properties: object): void {
		const elapsed = timer.getElapsed();

		logger.event({
			name: PERFORMANCE_METRIC_EVENT_NAME,
			message: 'Performance metric submitted',
			properties: {
				...properties,
				name: timer.name,
				duration: elapsed
			}
		});
	}
}

export default PerformanceLogger;
