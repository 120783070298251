import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const authModule = (defaults: any) => ({
	state: {
		token: defaults.token,
		isAuthenticated: defaults.isAuthenticated,
		isAuthenticating: false,
		error: ''
	},
	actions,
	mutations,
	getters
});

export default authModule;
