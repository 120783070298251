import { RbpInterface } from '@/interfaces';

export default {
	adviceLoading(state: RbpInterface): boolean {
		return state.isLoading;
	},
	advice(state: RbpInterface): string | null {
		return state.advice;
	},
	error(state: RbpInterface): any {
		return state.error;
	}
};
