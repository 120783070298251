import { AppInformationInterface } from '@/interfaces';
import {
	STORE_DIAGNOSTIC_INFORMATION,
	GET_CONFIG_SUCCESS,
	GET_CONFIG_FAILED
} from '@/store/types';

export default {
	[STORE_DIAGNOSTIC_INFORMATION](state: AppInformationInterface, payload: any): void {
		const {
			host, platform, version, supportedRequirementSets
		} = payload;
		state.diagnosticInformation = {
			host, platform, version, supportedRequirementSets
		};
	},
	[GET_CONFIG_SUCCESS](state: AppInformationInterface, payload: any): void {
		const {
			appVersion, hostLocation, enableExperimentalFeatures,
			blockOnSendWhenError, onSendErrorTags, suppressAutoLoginForUserIds
		} = payload;
		state.appVersion = appVersion;
		state.hostLocation = hostLocation;
		state.enableExperimentalFeatures = enableExperimentalFeatures;
		state.blockOnSendWhenError = blockOnSendWhenError;
		state.onSendErrorTags = onSendErrorTags;
		state.suppressAutoLoginForUserIds = suppressAutoLoginForUserIds;
	},
	[GET_CONFIG_FAILED](state: AppInformationInterface, err: any): void {
		state.error = err;
	}
};
