import AnalyticJourneyId from '@common/enums/analyticJourneyId';
import { OfficeProxy } from '@egress/officejs-proxy';

class AnalyticsEvent {
	id : AnalyticJourneyId;

	journeyId: string;

	timestamp: string;

	arguments: Record<string, string>;

	constructor(eventType: AnalyticJourneyId, messageId: string) {
		const clientInfo = OfficeProxy.getClientInformation();

		this.id = eventType;
		this.journeyId = messageId;
		this.timestamp = new Date(Date.now()).toISOString();
		this.arguments = {
			clientHost: clientInfo.hostName
		};
	}

	async addData(data: object): Promise<void> {
		this.arguments = {
			...data,
			...this.arguments
		};
	}
}

export default AnalyticsEvent;
