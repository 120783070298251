export default {
	onSend: 1000,
	handleMoveAllRecipients: 2000,
	handleRemoveRecipient: 3000,
	handleReplaceRecipient: 4000,
	hasStorage: 5000,
	opinionId: 6000,
	adviceHandler: 7000,
	recipientCell: 8000,
	accountDetails: 9000,
	labelSelect: 10000,
	onSendPrevent: 11000,
	ssoRedirect: 12000,
	ssoSuccess: 13000,
	login: 14000,
	messageCompose: 15000,
	sessionExpired: 16000,
	authMixIn: 17000,
	ssoHandler: 18000,
	requestLabelPrompt: 19000,
	requestTagPrompt: 20000,
	demResolver: 21000,
	diagnosticsDialog: 22000,
	authClaims: 23000,
	lftUpload: 24000
};
