<script lang='ts'>
import Vue from 'vue';

export default Vue.extend({
	name: 'Eds-Input',
	props: {
		id: {
			type: String,
			required: true
		},
		type: {
			type: String,
			default: 'text',
			validator(value: string) {
				// The value must match one of these strings
				// can expand as more HTML5 types are added
				return ['text', 'email', 'password'].indexOf(value) !== -1;
			}
		},
		label: {
			type: String,
			required: true
		},
		placeholder: {
			type: String,
			default: ''
		},
		required: {
			type: Boolean,
			default: false
		},
		allowAutocomplete: {
			type: Boolean,
			default: true
		},
		showOptionalText: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		value: {
			type: String,
			default: ''
		},
		hasError: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		getInputClasses() {
			return {
				'eds-input': true,
				'eds-form-error': this.hasError
			};
		},
		getLabelClasses() {
			return {
				'eds-label': true,
				'eds-form-error-text': this.hasError
			};
		},
		getAutocompleteStatus() {
			if ((this as any).type === 'password') return 'off';
			return this.allowAutocomplete ? 'on' : 'off';
		}
	}
});
</script>

<template>
  <div class="my-2">
    <label
      :for="id"
      :class="getLabelClasses">
      {{label}}
      <span
        v-if="required"
        class="eds-label-required">
        &nbsp;({{ $t('common.required') }})
      </span>
      <span
        v-else-if="showOptionalText"
        class="eds-label-optional">
        &nbsp;({{ $t('common.optional') }})
      </span>
    </label>
    <input
      :id="id"
      :autocomplete="getAutocompleteStatus"
      :type="type"
      :placeholder="placeholder"
      :required="required"
      :aria-required="required"
      :disabled="disabled"
      :value="value"
      @input="$emit('input', $event.target.value)"
      :class="getInputClasses" />
  </div>
</template>

<style lang="scss" scoped>
@import '../../assets/eds/variables.scss';

div#app.theme-dark {
	.eds-input {
		border: 1px solid $grey-4;
		color: $typeColor;
	}

	.eds-input:hover {
		border: 1px solid #393F53;
		box-shadow: 0 3px 6px #00000029;
	}

	.eds-input::placeholder {
		color: $grey-4;
	}
}
</style>
