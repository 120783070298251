import { gzip } from 'pako';

const buildRequestOption = (
	method: string,
	token: string,
	body: any,
	headers: any,
	compressRequestBody: boolean = false
):any => {
	const requestHeaders: { [key: string] : string } = {
		'Content-Type': 'application/json',
		...headers
	};

	const jsonBody = body ? JSON.stringify(body) : null;
	let requestBody: any = jsonBody;
	if (compressRequestBody && jsonBody && jsonBody.length > 1024) {
		requestBody = gzip(jsonBody);
		requestHeaders['Content-Encoding'] = 'gzip';
	}

	if (token) {
		requestHeaders.Authorization = `Bearer ${token}`;
	}

	return {
		method,
		headers: requestHeaders,
		body: requestBody || null
	};
};

const buildGetRequestOption = (token: string = '', headers: any = {}):any => buildRequestOption('GET', token, null, headers);

const buildPostRequestOption = (body: any = null, token: string = '', headers: any = {}, compressRequestBody: boolean = false):any => buildRequestOption('POST', token, body, headers, compressRequestBody);

const buildDeleteRequestOption = (token: string = '', headers: any = {}):any => buildRequestOption('DELETE', token, null, headers);

const buildPatchRequestOption = (token: string = '', headers: any = {}):any => buildRequestOption('PATCH', token, null, headers);

export {
	buildGetRequestOption,
	buildPostRequestOption,
	buildPatchRequestOption,
	buildDeleteRequestOption
};
