<script lang='ts'>
import Vue from 'vue';
import { theme } from '@common/enums';
import { OfficeProxy } from '@egress/officejs-proxy';
import { DIALOG_CLOSE } from '@common/constants';
import goToExternalLink from '@common/utils/externalLink';
import { Base64 } from 'js-base64';
import EdsButton from '@/components/eds/Button.vue';
import CapturedMatchesTable from '@/components/dem/CapturedMatchesTable.vue';
import egressLogoLight from '@/assets/img/eds-light-logo.svg';
import egressLogoDark from '@/assets/img/eds-dark-logo.svg';
import PromptCountdownTimer from '@/views/dlpPrompts/PromptCountdownTimer.vue';

export default Vue.extend({
	name: 'MessagePrompt',
	data() {
		return {
			title: 'Unable to Send Message',
			message: '',
			countdownTimeRemaining: parseInt(this.countdown, 10)
		};
	},
	props: {
		countdown: {
			type: String,
			required: true
		},
		msg: {
			type: String,
			required: true
		},
		matches: {
			type: String,
			required: true
		},
		appVersion: {
			type: String,
			required: true
		},
		theme: {
			type: String,
			required: true
		}
	},
	methods: {
		onClickOverride(event: any) {
			if (event.target.tagName.toLowerCase() === 'a') {
				goToExternalLink(event.target.href);
			}
		},
		async setupPrompt(): Promise<void> {
			if (this.msg) {
				const decodedMsg = Base64.decode(this.msg);
				this.message = decodedMsg;

				// separate lines based on carriage return of new line and filter out empty spaces
				const separateLines = decodedMsg.split(/\r?\n|\r|\n/g);

				// Multiple lines present so first line is the title
				if (separateLines.length > 1) {
					const firstText = separateLines[0].trim();
					this.title = firstText;
					this.message = decodedMsg.replace(firstText, '');
				}
			} else {
				this.message = `${this.$t('account.version')}: ${this.appVersion}`;
			}
		},
		async closePrompt(): Promise<void> {
			OfficeProxy.messageParent(JSON.stringify({
				type: DIALOG_CLOSE
			}));
		}
	},
	components: {
		PromptCountdownTimer,
		EdsButton,
		CapturedMatchesTable
	},
	computed: {
		getEgressLogoSrc(): any {
			return (this.theme === theme.Dark)
				? egressLogoDark
				: egressLogoLight;
		},
		getMatches(): any {
			if (this.matches.length) {
				return JSON.parse(Base64.decode(this.matches));
			}
			return [];
		}
	},
	mounted(): void {
		this.setupPrompt();
	}
});
</script>

<template>
  <div class="container">
    <div class="row">
      <div id="message-prompt" class="dlp-prompt">
        <PromptCountdownTimer
          :timeRemaining="this.countdownTimeRemaining"
          @timerExpired="this.closePrompt" />
        <div>
          <img :src="getEgressLogoSrc" alt="egressIcon" aria-hidden="true">
        </div>
        <div class="dlp-title pt-3" v-html="this.title" />
        <div
          class="dlp-info py-3"
          v-html="this.message"
          v-on:click.prevent="onClickOverride($event)"
          v-on:keydown="onClickOverride($event)"
        />
        <div v-if="this.getMatches.length" class="py-3">
          <CapturedMatchesTable
            :matches="this.getMatches" />
        </div>
        <fieldset>
          <div class="base mt-4 mb-4">
            <div class="controls">
              <EdsButton
                id="btn-send-ok"
                buttonType="prime"
                type="submit"
                @click="closePrompt">
                {{ $t('common.ok') }}
              </EdsButton>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</template>
