import { RbpInterface } from '@/interfaces';
import {
	LOGOUT_SUCCESS,
	RBP_OPINION_REQUESTED,
	RBP_OPINION_SUCCESS,
	RBP_OPINION_FAILED,
	RBP_OPINION_RESET,
	STORE_OPINION_CONTEXT_ID
} from '@/store/types';

const getDefaultState = () => ({
	advice: null,
	isLoading: false,
	error: null,
	opinionContextId: null
});

export default {
	[LOGOUT_SUCCESS](state: RbpInterface): void {
		Object.assign(state, getDefaultState());
	},
	[RBP_OPINION_REQUESTED](state: RbpInterface): void {
		state.isLoading = true;
		state.error = null;
	},
	[RBP_OPINION_SUCCESS](state: RbpInterface, payload: any): void {
		state.error = null;
		state.isLoading = false;
		state.advice = payload;
	},
	[RBP_OPINION_FAILED](state: RbpInterface, payload: any): void {
		state.isLoading = false;
		const { localizationId, userEventId } = payload;
		state.error = { localizationId, userEventId };
	},
	[RBP_OPINION_RESET](state: RbpInterface): void {
		state.isLoading = false;
		state.advice = null;
	},
	[STORE_OPINION_CONTEXT_ID](state: RbpInterface, payload: any): void {
		state.opinionContextId = payload;
	}
};
