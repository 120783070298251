import Vue from 'vue';
import { isNavigationFailure, NavigationFailureType } from 'vue-router';
import { OfficeProxy } from '@egress/officejs-proxy';
import { CLIENT_STATE } from '@common/constants';
import ClientStateInfoStore from '@common/dbStorage/clientState';
import AnalyticsEventLogger from '@common/analyticsEventLogger';
import { AuthenticationMode } from '@common/enums';
import { deleteEsiToken, getEsiToken } from '@common/token';
import StorageError from '@common/errors/storageError';
import AuthError from '@common/errors/authError';
import { LogEventIds } from '@common/logger';
import { LOGOUT_REQUESTED, LOGOUT_SUCCESS } from '@/store/types';

const failedVerifyingUserSession = LogEventIds.authMixIn + 1;

export default Vue.extend({
	name: 'authMixin',
	methods: {
		redirectToItemView(): void {
			this.$router.push({
				name: 'Compose'
			}).catch((error) => {
				if (!isNavigationFailure(error, NavigationFailureType.duplicated)
				&& !isNavigationFailure(error, NavigationFailureType.cancelled)) {
					throw error;
				}
			});
		},
		async clearPersistedClientState(): Promise<void> {
			const clientStateInfoStore = new ClientStateInfoStore(CLIENT_STATE);
			await deleteEsiToken();
			await clientStateInfoStore.removeClientStateInfo();
		},
		async logout(): Promise<void> {
			if (this.$store.state.auth.isAuthenticating) {
				return;
			}

			try {
				this.$logger.info({
					message: 'Logging out user.',
					properties: { source: 'authMixin.js', method: 'logout' }
				});
				// Flush all prevent analytics event log
				await AnalyticsEventLogger.flush();
				await this.$store.dispatch(LOGOUT_REQUESTED);
			} catch (err) {
				const { errorCode, userEventId } = (err as any);
				this.$logger.warn({
					message: 'Failed to logout user.',
					properties: { source: 'authMixin.js', method: 'logout', userEventId }
				}, errorCode);
			} finally {
				await this.clearPersistedClientState();
				this.$store.commit(LOGOUT_SUCCESS);

				if (OfficeProxy.isRunningInDialog()) {
					await this.$router.push({
						name: 'Login',
						query: {
							showMessage: 'true',
							authMode: AuthenticationMode.Manual
						}
					});
				} else {
					await this.$router.push({
						name: 'Login',
						query: { authMode: AuthenticationMode.Manual }
					});
				}
			}
		},
		redirectToSessionExpired(canRetry: string): void {
			this.$router.push({
				name: 'SessionExpired',
				params: {
					canRetry
				}
			});
		},
		async checkUserAuthentication(): Promise<any> {
			try {
				this.$logger.info({
					message: 'Ensuring user has valid authentication session.',
					properties: { source: 'authMixin.js', method: 'checkUserAuthentication' }
				});

				await getEsiToken();

				return { expired: false };
			} catch (err) {
				const { errorCode, userEventId } = (err as any);
				this.$logger.warn({
					message: 'Verifying user session authentication failed.',
					properties: {
						source: 'authMixin.js', method: 'checkUserAuthentication', userEventId, serverErrorCode: errorCode
					}
				}, failedVerifyingUserSession);

				const canRetry = !(err instanceof StorageError || err instanceof AuthError);
				return { expired: true, canRetry };
			}
		}
	}
});
