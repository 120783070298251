import IndexedDbRepository from '@common/dbStorage/indexedDbStore';
import { OpinionInterface } from '@common/interfaces';

/* eslint-disable no-undef */
export default class OpinionStore {
	store: IndexedDbRepository<OpinionInterface>;

	/**
	 * Create an instance of the OpinionStore class
	 * @param {string | null} storeName Provide a name to give this opinion store.
	 */
	constructor(storeName: string | null = null) {
		this.store = new IndexedDbRepository(storeName ?? 'opinion-store', 86400000);
	}

	/**
	 * Retrieves the value of the opinion with the given hash
	 * @param {string} hash The hash of the opinion to retrieve
	 * @returns {object | StorageError}
	 */
	async getOpinion(hash: string): Promise<OpinionInterface | null> {
		const found = await this.store.get(hash);
		if (!found) return null;
		return found;
	}

	/**
	 * Updates the value of the opinion with the given hash
	 * @param {string} hash The hash value of the message
	 * @param {OpinionInterface} opinion The value to update the opinion with
	 * @returns {void | StorageError}
	 */
	async updateOpinion(hash: string, opinion: OpinionInterface): Promise<void> {
		await this.store.update(hash, opinion);
	}

	/**
	 * Removes the opinion with the given hash from the store.
	 * Does nothing if such an opinion doesn't exist
	 * @param {string} hash The hash of the opinion to remove
	 * @returns {void | StorageError}
	 */
	async removeOpinion(hash: string): Promise<void> {
		await this.store.remove(hash);
	}

	/**
	 * Drops the instance of the created store.
	 * @returns {void | StorageError}
	 */
	async dropStore(): Promise<void> {
		await this.store.dropStore();
	}
}
