const taskpane = {
	api: {
		errors: {
			internalServerError: 'Internal server error.',
			noResponse: 'No response from remote server.',
			getMsgInfoFailed: 'There was an error processing this message. Please try again.',
			requestFailed: 'There was an error processing this request.',
			requestingRbpOpinionFailed: 'There was an error requesting advice for this message.',
			requestingRbpOpinionForbidden: 'Your account is not configured to use Egress Prevent. It will not be possible to provide advice for this message.',
			rbpServiceEndpointNotSet: 'Your Egress Prevent details are either missing or mis-configured. Please contact Egress Support.',
			processingAuthRequestFailed: 'There was a problem processing your login request. Please try again.',
			invalidUsernameOrPassword: 'The Egress ID and password are either unknown or invalid. Please try again.',
			accountLocked: 'The account associated with this Egress ID is temporarily locked.',
			accountDisabled: 'The account associated with this Egress ID is disabled.',
			ssoNotSupported: 'Your Egress Authentication server does not support Single Sign-On. Please contact Egress Support.',
			requiresGraphConsent: 'Your account has not been given administrator consent. Please contact Egress Support.',
			attachmentUploadFailed: 'There was an error uploading the content of the file. Please try again.'
		}
	},
	rbp: {
		generalFailure: 'An error occurred whilst processing this Egress Prevent request.',
		noResponse: 'There was no response from Egress Prevent. Please try again.',
		requestFailed: 'The Egress Prevent request could not be sent. Please try again.',
		emptyState: "We don't have any advice for you yet. Add a recipient to receive advice.",
		registerChangeEventFailure: 'Event registration failed, we can’t provide advice at this time.',
		loadingAdvice: 'Retrieving advice',
		removeRecipient: 'Remove {email} from the recipient list',
		replaceRecipient: 'Replace',
		moveToBccText: 'Move to Bcc',
		riskTitle: 'This email is {riskLevel} risk',
		details: 'Details',
		viewAllRecipients: 'View all recipients',
		didYouMean: 'Did you mean',
		unableToProvideFullAdvice: 'We are currently unable to determine the risk level of this email.',
		unknownAdviceToUser: 'Please double check the recipients and proceed with caution.',
		wentWrong: 'Something went wrong.',
		lowRisk: 'low',
		mediumRisk: 'medium',
		highRisk: 'high',
		opinionContextIdMissing: 'We were unable to load some message properties required to offer you advice.',
		tpStopAdvice: 'Egress has flagged this email as high risk and subsequently blocked it. Please check your recipients.',
		hideVerifiedRecipients: 'Hide verified recipients',
		showVerifiedRecipients: 'Show verified recipients'
	},
	validation: {
		switchIdEmail: 'Please enter a valid email address',
		switchIdRequired: 'Please enter your Egress ID',
		passwordRequired: 'Please enter your password'
	},
	common: {
		appTitle: 'Egress for Office',
		egressLogoImgAlt: 'Logo for Egress Software Technologies',
		egressPrevent: 'Egress Prevent',
		emailPlaceholder: 'user@domain.com',
		loading: 'Loading',
		optional: 'Optional',
		required: 'Required',
		switchID: 'Egress ID',
		supportInfoID: 'Support ID',
		tryAgain: 'Try Again',
		login: 'Login',
		ok: 'Ok',
		send: 'Send',
		cancel: 'Cancel',
		close: 'Close',
		error: 'Error',
		failedToLoad: 'Egress for Office has failed to load',
		failedToLoadLocalStorage: 'Egress Prevent requires access to local storage to work and this was unavailable.',
		fromAddressChanged: "'From' address changed",
		fromAddressChangedDescription: "Because you changed the 'from' address during the composition session we can't give you Prevent advice.<br />To get Prevent advice <strong>please create a new email.</strong>",
		messageNotSent: 'Message not sent',
		messageNotSentDetail: 'Your message was not sent before you clicked away. The message is saved in drafts and will need to be re-sent.'
	},
	account: {
		btnLogout: 'Logout',
		yourSettings: 'Your settings',
		organizationName: 'Organisation Name',
		switchServer: 'Egress Server',
		toggleAria: 'Click this to view details about your account and to logout',
		version: 'Version',
		manifestVersion: 'Manifest Version',
		environment: 'Environment',
		experimentalTitle: 'Experimental features',
		experimentalWarning: 'These features are experimental and should be used with caution.',
		toggleDarkMode: 'Enable dark mode',
		nextPolicyRefresh: 'Next policy refresh',
		forcePolicyRefresh: 'Refresh policies now',
		refreshPoliciesError: 'There was a problem refreshing your policies. Please try again.',
		diagnosticsButton: 'Diagnostic info',
		diagnosticsTitle: 'Diagnostic Information',
		appearance: 'Appearance',
		theme: 'Theme',
		settings: 'Settings',
		support: 'Support'
	},
	diagnostics: {
		host: 'Host',
		platform: 'Platform',
		supportedRequirementSets: 'Supported Requirement sets'
	},
	login: {
		btnContinue: 'Continue',
		btnDecline: 'Disable Egress for this message',
		btnLoginAuthenticating: 'Logging in...',
		lblPassword: 'Password',
		loginMessage: 'Please log in to continue',
		waitingForMessage: 'Logging in...'
	},
	labelSelect: {
		sectionTitle: 'Protection level',
		errorOption: 'Error. Click to try again.',
		lblClassification: 'Select protection level',
		placeholder: 'Click here to select protection...',
		loadError: 'Error loading protection levels.',
		headerError: 'Failed to apply protection level.'
	},
	prompt: {
		loadError: 'There was an error loading this dialog, please close and try again. If issue persist, please contact your administrator.'
	},
	requestTagPrompt: {
		defaultTitle: 'Additional Information Required',
		missingDataError: 'Both question and answer fields must be completed',
		answerValidationError: 'The answer does not meet the requirements'
	},
	ssoHandler: {
		backToLogin: 'Go Back',
		dialogErrorDefault: 'There was an issue displaying the SSO window',
		loginDirection: 'Please click the button below to complete your login.',
		popupInfo: 'Your organisation has policies to allow you to login using single sign on (SSO).',
		popupRedirect: "Please wait while you are redirected to your organisation's designated SSO provider.",
		completeLoginButton: 'Login'
	},
	ssoCallback: {
		loading: 'Logging in...'
	},
	ssoSuccess: {
		loading: 'Logged in successfully, loading...'
	},
	dialog: {
		httpsRequired: 'The target domain is not using HTTPS',
		multipleDialogs: 'There can only be one dialog window open at a time',
		dismissed: 'The sign-in window was closed',
		untrustedDomain: 'The target domain is not trusted',
		urlNotFound: 'The target domain could not be found'
	},
	ssoError: {
		errorFirstLine: 'There was a problem with this Single Sign On (SSO) request.',
		errorSecondLine: 'If this problem persists please contact your system administrator.'
	},
	notFound: {
		title: '404',
		subtitle: 'Page not found',
		p1: 'We couldn’t find the page you wanted, please try again.',
		p2: 'If the problem persists contact your system administrator.',
		btnBack: 'Back'
	},
	sessionExpired: {
		title: 'Auth Token',
		unableToReauth: 'We were not able to re-authorise this application.',
		btnLogin: 'Log in'
	},
	onSend: {
		prevent: {
			timeRemaining: 'This dialog will close in {remaining} and the message will not be sent.',
			oneMinuteRemaining: '1 minute',
			twoMinutesRemaining: '2 minutes'
		},
		auth: {
			notLoggedIn: 'Egress cannot provide advice for this message as there is an issue with your current session.',
			openRibbonAddin: 'Please click the Egress button in the Outlook ribbon to renew your session and then send your email again to receive advice.',
			openAddin: 'Please open and log in to the Egress add-in and send your email again.'
		},
		error: {
			rbpRequestingOpinionForbidden: 'Your account is not configured to use Egress Prevent. It will not be possible to provide advice for this message, please proceed with caution.',
			preventAdviceError: 'We were unable to obtain Prevent advice for this email, please proceed with caution.',
			attachmentScanningFailed: 'Egress Prevent has been unable to scan one or more of your attachments, please proceed with caution as we are unable to provide advice at this time.',
			general: 'There was an error processing this message. You can close this dialog and try to send the message again or you can send anyway, please proceed with caution.'
		},
		common: {
			sendAnyway: 'Send anyway',
			sendEmail: 'Send email',
			closeDialog: 'Please close this dialog and open the Egress add-in again'
		}
	},
	requirementsCheckFailed: {
		headline: 'Sorry',
		explanation: "Outlook doesn't support the minimum requirements for this add-on",
		suggestion: 'Please update Outlook and try again'
	},
	dlp: {
		matchLocationFriendlyNames: {
			messageBody: 'Subject/Email Body',
			addresses: 'Addresses'
		}
	},
	adviceHandler: {
		sectionTitle: 'Email advice',
		header: {
			lowRisk: 'This email is low risk',
			mediumRisk: 'This email is medium risk',
			highRisk: 'This email is high risk'
		}
	},
	lft: {
		sectionTitle: 'Share a large file',
		toggle: {
			tooltip: 'To attach large files to this mail,\n'
                + 'toggle the button on. This will add\n'
                + 'any files already attached to this\n'
                + 'mail and allow you to add files of\n'
                + 'any size. These files will be sent to\n'
                + 'the recipient using a Large File\n'
                + 'Transfer link.\n'
                + '\n'
                + "If you don't want to send via this\n"
                + 'service, toggle off to send files\n'
                + 'attached to the email.',
			label: 'Large File Transfer'
		},
		existingAttachmentsWarning: {
			header: 'Existing attachments',
			body: 'All files including those that are already attached to this email will be sent using Large File Transfer.',
			dismissButton: 'Dismiss'
		},
		uploader: {
			cancelLabel: 'Click to Cancel',
			retryLabel: 'Click to Retry',
			drop: {
				header: 'Drag and drop or <em>click here</em>',
				body: 'to upload your file'
			}
		},
		warnings: {
			progress: {
				title: 'Upload in progress',
				message: 'Do not navigate away from the task pane until upload is complete.'
			},
			error: {
				title: 'Error uploading files',
				message: 'One or more of your files failed to upload, please try again.'
			},
			incomplete: {
				title: 'Incomplete Uploads',
				message: 'One or more of your files needs attention.'
			}
		},
		files: {
			header: 'Uploaded file(s)',
			deleteButtonAria: 'delete',
			otherAttachments: 'Existing attachment(s)'
		}
	},
	footer: {
		branding: 'Powered by Egress'
	},
	url: {
		support: 'https://support.egress.com/s/article/Egress-Protect-User-Guide-Web-Add-In'
	}
};

const onSend = {
	notifications: {
		loadingMessageProperties: 'Egress is loading information about this message.',
		loadingUserState: 'Egress is loading information about the current user.',
		submittingAttachments: 'Egress is submitting your attachments for processing.',
		requestingRbpOpinion: 'Egress is requesting Prevent advice for this message.',
		evaluatingRules: 'Egress is checking this message in accordance with your organisation\'s policies.',
		onSendFailedMsg: 'An error occurred sending this email. Please try again and contact your Administrator if the problem persists.',
		performingLftUpload: 'Egress is processing your Large File Transfer.',
		lftUploadInProgress: 'Incomplete Large File Transfer upload. Please ensure all files have uploaded successfully in your Egress task pane before sending.',
		lftUploadFailed: 'One or more Large File Transfer uploads have failed. Please resolve this error in your Egress task pane before sending.',
		lftUploadError: 'Large File Transfer failed. This email cannot be sent at this time.',
		lftDemTimeoutError: 'Large File Transfer failed. This email cannot be sent at this time, please try again.'
	}
};

const dateTimeFormats = {
	short: { year: 'numeric', month: 'short', day: 'numeric' },
	long: {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		weekday: 'short',
		hour: 'numeric',
		minute: 'numeric'
	}
};

export {
	taskpane,
	onSend,
	dateTimeFormats
};
