class ApiError extends Error {
	localizationId?: string;

	errorCode?: string;

	userEventId?: string;

	constructor(message: string, localizationId?: string, errorCode?: string, userEventId?: string) {
		super(message);
		this.localizationId = localizationId;
		this.errorCode = errorCode;
		this.userEventId = userEventId;
	}
}

export default ApiError;
