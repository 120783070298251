<script lang='ts'>
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
	name: 'PromptCountdownTimer',
	data() {
		return {
			countdownTimer: 0 as number,
			countdownTimeRemaining: this.timeRemaining
		};
	},
	props: {
		timeRemaining: {
			type: Number,
			required: true
		}
	},
	methods: {
		updateTimer(): void {
			this.countdownTimeRemaining -= 1;

			if (this.countdownTimeRemaining > 0) return;

			this.$emit('timerExpired');
		}
	},
	components: {
	},
	computed: {
		...mapGetters(['darkModeEnabled']),
		getTimerWarning(): any {
			if (this.timeRemaining <= 60) {
				return this.$t('onSend.prevent.timeRemaining', {
					remaining: this.$t('onSend.prevent.oneMinuteRemaining')
				});
			}

			return this.$t('onSend.prevent.timeRemaining', {
				remaining: this.$t('onSend.prevent.twoMinutesRemaining')
			});
		},
		getProgressComplete(): string {
			const isDarkMode = (this as any).darkModeEnabled;
			const progressClass = isDarkMode ? 'eds-progress-complete--dark' : 'eds-progress-complete';
			const progressWarningClass = isDarkMode ? 'eds-progress-complete--dark-warning' : 'eds-progress-complete-warning';

			if (this.timeRemaining <= 60) {
				return progressWarningClass;
			}

			return progressClass;
		},
		getProgressBarBackground(): string {
			return ((this as any).darkModeEnabled)
				? 'eds-progress-bg--dark'
				: 'eds-progress-bg-alt';
		},
		getProgressStyle(): string {
			const width = Math.floor((this.countdownTimeRemaining / 150) * 100);
			return `width:${width}%`;
		},
		getTimerClasses(): string {
			if (this.countdownTimeRemaining <= 60) {
				return 'warning is-text-grey-7';
			}

			return 'grey-5 is-text-grey-1 is-border-grey-1';
		}
	},
	mounted(): void {
		this.countdownTimer = window.setInterval(this.updateTimer, 1000);

		window.addEventListener('beforeunload', () => {
			clearInterval(this.countdownTimer);
		});
	},
	beforeDestroy(): void {
		clearInterval(this.countdownTimer);
	}
});
</script>

<template>
  <div
    v-if="countdownTimeRemaining < 150"
    id="prompt-timer"
    class="row pb-3">
    <div
      :class="['timer', { 'timer-warning': countdownTimeRemaining <= 60 }]"
      class="pt-2 pb-3 px-3">
      <p>
        {{ getTimerWarning }}
      </p>
      <div class="eds-progress-wrapper py-2">
        <div :class="getProgressBarBackground" />
        <div :class="getProgressComplete" :style="getProgressStyle" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

@import '../../assets/eds/variables';

div#app.theme-dark {
	#prompt-timer {
		.eds-progress-bg {
			background: #2C2D2D
		}

		.timer{
			background: #3F3F3F;
			border: none;
			color: white;
		}

		.timer-warning {
			background: #7C0000;
			border: none;
			color: white;
		}
	}
}

.timer {
	flex: 1;
	background: $grey-5;
	border: 1px solid $grey-1;
	color: $grey-1;
	p{
		font-size: 1rem;
	}
}

.timer-warning{
	background: $clr-warning;
	color: $grey-7;
	font-size: 12px;
	p{
		font-size: 12px;
	}
}
</style>
