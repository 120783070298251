import { buildGetRequestOption, buildPostRequestOption } from '@common/httpClient/buildRequestOptions';
import AuthError from '@common/errors/authError';
import ApiError from '@common/errors/apiError';
import FetchError from '@common/errors/fetchError';
import ValidationError from '@common/errors/validationError';
import { addSeconds } from 'date-fns';

/**
 * Log the user in using username and password authentication
 * @param credentials The credentials of the user
 * @returns {Object}
 */
async function login(
	credentials: object
): Promise<{ token: { data: string, expiresInSeconds: number }, user: object }> {
	try {
		const response = await fetch(
			'/api/v2/auth/login',
			buildPostRequestOption(credentials)
		);
		const responseData = await response.json();

		// Handle failures
		if (!response.ok) {
			switch (response.status) {
				case 400:
					throw new ValidationError(
						'Server validation of login request failed.',
						responseData.localizationId,
						responseData.code
					);
				default:
					throw new ApiError(
						`Failed logging user in. Server returned non-2xx status code. StatusCode:[${response.status}].`,
						responseData?.localizationId,
						responseData?.code,
						responseData?.userEventId
					);
			}
		}

		return responseData;
	} catch (err) {
		if (err instanceof ValidationError || err instanceof ApiError) {
			throw err;
		}
		throw new FetchError('Fetch request could not be sent.', 'api.errors.fetchFailed');
	}
}

/**
 * Log the current user out of the application and invalidate their auth tokens
 * @param switchId Switch ID of the user to logout
 * @param authToken The users JWT authentication token
 * @returns {void}
 */
async function logout(switchId: string | number | boolean, authToken: string): Promise<void> {
	try {
		const response = await fetch(
			`/api/v2/auth/logout?switchId=${encodeURIComponent(switchId)}`,
			buildGetRequestOption(authToken)
		);

		// Handle failures
		if (!response.ok) {
			const responseData = await response.json();
			throw new ApiError(
				`Failed logging user in. Server returned non-2xx status code. StatusCode:[${response.status}].`,
				responseData?.localizationId,
				responseData?.code,
				responseData?.userEventId
			);
		}
	} catch (err) {
		if (err instanceof ApiError) {
			throw err;
		}
		throw new FetchError('Fetch request could not be sent.', 'api.errors.fetchFailed');
	}
}

/**
 * Gets the SSO endpoint for the given Switch ID, or null if no endpoint is configured
 * @param switchId Switch ID of the user requesting the SSO endpoint
 * @returns {String}
 */
async function getSsoEndpoint(
	switchId: string | number | boolean
): Promise<{ redirectUrl: string }> {
	try {
		const response = await fetch(
			`/api/v2/auth/ssologin?switchId=${encodeURIComponent(switchId)}`,
			buildGetRequestOption()
		);
		const responseData = await response.json();

		// Handle failures
		if (!response.ok) {
			switch (response.status) {
				case 400:
					throw new ValidationError(
						'Server validation of SSO endpoint configuration request failed.',
						responseData.localizationId,
						responseData.code
					);
				default:
					throw new ApiError(
						`Failed getting SSO endpoint configuration. Server returned non-2xx status code. StatusCode:[${response.status}].`,
						responseData?.localizationId,
						responseData?.code,
						responseData?.userEventId
					);
			}
		}

		return responseData;
	} catch (err) {
		if (err instanceof ValidationError || err instanceof ApiError) {
			throw err;
		}
		throw new FetchError('Fetch request could not be sent.', 'api.errors.fetchFailed');
	}
}

/**
 * Sends the token and encoded sso payload to the API for validation
 * Successful response includes the token and user details
 * @param token token obtained from SSO
 * @param encoded base64 encoded payload obtained from SSO
 * @returns {Object}
 */
async function getSsoAuthResponse(
	token: string | number | boolean,
	encoded: string
): Promise<object> {
	try {
		const response = await fetch(
			`/api/v2/auth/ssoresponse/${encoded}?token=${encodeURIComponent(token)}`,
			buildGetRequestOption()
		);
		const responseData = await response.json();

		// Handle failures
		if (!response.ok) {
			switch (response.status) {
				case 400:
					throw new ValidationError(
						'Server validation of SSO auth response request failed.',
						responseData.localizationId,
						responseData.code
					);
				default:
					throw new ApiError(
						`Failed getting SSO auth response. Server returned non-2xx status code. StatusCode:[${response.status}].`,
						responseData?.localizationId,
						responseData?.code,
						responseData?.userEventId
					);
			}
		}

		return responseData;
	} catch (err) {
		if (err instanceof ValidationError || err instanceof ApiError) {
			throw err;
		}
		throw new FetchError('Fetch request could not be sent.', 'api.errors.fetchFailed');
	}
}

/**
 * Renews the authentication and refresh tokens for the current user
 * @param authToken The users JWT authentication token
 * @returns {Object}
 */
async function renewAuthTokens(
	authToken: string
): Promise<{ data: string; expiresAtUtc: string; user: object; }> {
	try {
		const response = await fetch(
			'/api/v2/auth/refresh-tokens',
			buildGetRequestOption(authToken)
		);
		const responseData = await response.json();

		// Handle failures
		if (!response.ok) {
			switch (response.status) {
				case 401:
					throw new AuthError(
						'Failed renewing authentication tokens. Users session has either expired or is invalid.',
						responseData?.localizationId,
						responseData?.code,
						responseData?.userEventId
					);
				default:
					throw new ApiError(
						`Failed renewing authentication tokens. Server returned non-2xx status code. StatusCode:[${response.status}].`,
						responseData?.localizationId,
						responseData?.code,
						responseData?.userEventId
					);
			}
		}

		const { data, expiresInSeconds } = responseData.token;
		const { user } = responseData;
		const utcDate = new Date().toISOString();
		const expiresAtUtc = addSeconds(new Date(utcDate), expiresInSeconds).toISOString();

		return {
			data,
			expiresAtUtc,
			user
		};
	} catch (err) {
		if (err instanceof AuthError || err instanceof ApiError) {
			throw err;
		}
		throw new FetchError('Fetch request could not be sent.', 'api.errors.fetchFailed');
	}
}

export {
	getSsoEndpoint,
	getSsoAuthResponse,
	renewAuthTokens,
	logout,
	login
};
