enum AnalyticJourneyId {

	Subject = 50000,
	Reply,
	ReplyAll,

	Forward,

	SendEmailClicked,

	SendEmailSucceeded,

	Classification,

	NewMailInspector,

	InlineResponse,

	PackageDecrypted,

	SaveToDraft,

	UserClosedPreviewPane,

	PermanentDecryptPressed,

	PermanentDecryptAccepted,

	PermanentDecryptCancelled,

	PolicyNoteAccepted,

	PolicyNoteDenied,

	PolicyNoteCompleted,

	UserRevokedAccess,

	UserUnrevokedAccess,

	RecipientAdded,

	RecipientRemoved,

	ComposeNewEmail,

	DialogCancelled,

	DialogDisplayed,

	DialogAccepted,

	RecipientsResolved,

	AddAttachment,

	RemoveAttachment,

	Encrypting,

	Encrypted,

	RecipientChanged,

	// DLP

	DlpCapture = Subject + 100,

	DlpDialogDisplayed,

	DlpDialogAccepted,

	DlpDialogCancelled,

	DlpCaptureTag,

	DialogSuppressionState,

	DlpDialogDropDownSelection,

	DlpDialogAcceptedWithAnswer,

	DlpDataChanged,

	DemEngineCompleted,

	ScoreRulesCompleted,

	// Outlook

	AuthenticationButtonInRibbonPressed = DlpCapture + 100,

	AuthenticationButtonInBackstagePressed,

	// Lft

	LftPanelShow = AuthenticationButtonInRibbonPressed + 100,

	LftPanelHide,

	LftAddAttachment,

	LftRemoveAttachment,

	LftRemoveAllAttachment,

	LftPanelOpenFileDialog,

	LftRibbonOpenFileDialog,

	LftDragAndDropFile,

	// Prevent

	RbpPanelShow = LftPanelShow + 100,

	RbpPanelHide,

	MovedToBcc,

	RbpPanelRemoveRecipient,

	RbpPanelReplaceRecipient,

	RbpCall,

	RbpCallFailed

}

export default AnalyticJourneyId;
