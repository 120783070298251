import getAppConfigInfo from '@common/httpClient/config/getAppConfigInfo';
import { ConfigurationInterface } from '@common/interfaces';
import {
	GET_CONFIG_REQUESTED,
	GET_CONFIG_SUCCESS,
	GET_CONFIG_FAILED
} from '@/store/types';

export default {
	async [GET_CONFIG_REQUESTED]({ commit }: any): Promise<void> {
		try {
			const {
				appInformation: {
					appVersion, hostLocation, enableExperimentalFeatures,
					blockOnSendWhenError, onSendErrorTags, suppressAutoLoginForUserIds
				}
			} = <ConfigurationInterface> await getAppConfigInfo(true);

			commit(GET_CONFIG_SUCCESS, {
				appVersion,
				hostLocation,
				enableExperimentalFeatures,
				blockOnSendWhenError,
				onSendErrorTags,
				suppressAutoLoginForUserIds
			});
		} catch (err) {
			commit(GET_CONFIG_FAILED, { err: (err as any).localizationId });
			throw err;
		}
	}
};
