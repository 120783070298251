<script lang='ts'>
/* global Office */
import { mapActions, mapGetters } from 'vuex';
import Version from '@common/utils/version';
import { OfficeProxy } from '@egress/officejs-proxy';
import lightLogo from '@/assets/img/eds-light-logo.svg';
import darkLogo from '@/assets/img/eds-dark-logo.svg';
import lightLoader from '@/assets/img/loading-b.svg';
import darkLoader from '@/assets/img/loading-b-dark.svg';
import { GET_CONFIG_REQUESTED } from '@/store/types';
import authMixin from '@/mixins/authMixin';
import { setUserManifestVersion } from '@common/utils/clientState';
import AnalyticsEventLogger from '@common/analyticsEventLogger';
import { AnalyticJourneyId } from '@common/enums';

export default authMixin.extend({
	name: 'Landing',
	props: {
		manifestVersion: String
	},
	data() {
		return {
			hostRequirements: {
				requirementSetConstraints: {
					DialogApi: '1.2'
				},
				minimumVersions: {
					[Office.PlatformType.OfficeOnline]: new Version(0, 0, 0, 0),
					[Office.PlatformType.Android]: new Version(0, 0, 0, 0),
					[Office.PlatformType.iOS]: new Version(0, 0, 0, 0),
					[Office.PlatformType.Mac]: new Version(0, 0, 0, 0),
					[Office.PlatformType.Universal]: new Version(0, 0, 0, 0),
					[Office.PlatformType.PC]: new Version(16, 0, 13530, 20424)
				} as any
			}
		};
	},
	methods: {
		...mapActions([GET_CONFIG_REQUESTED]),
		async checkAuth() {
			if (!(this as any).isAuthenticated) {
				await this.$router.push({
					name: 'Login'
				});
				return;
			}

			const userAuthentication = await this.checkUserAuthentication();
			if (userAuthentication.expired) {
				this.redirectToSessionExpired(userAuthentication.canRetry);
				return;
			}
			this.redirectToItemView();
		}
	},
	computed: {
		...mapGetters(['darkModeEnabled', 'isAuthenticated']),
		getLogoSrc(): any {
			return ((this as any).darkModeEnabled)
				? darkLogo
				: lightLogo;
		},
		getLoaderSrc(): any {
			return ((this as any).darkModeEnabled)
				? darkLoader
				: lightLoader;
		}
	},
	async mounted(): Promise<void> {
		this[GET_CONFIG_REQUESTED]();
		// Save user manifest version numeric values only
		setUserManifestVersion(this.manifestVersion?.replace(/[^\d.-]/g, ''));
		if (!OfficeProxy.isHostSupported(
			this.hostRequirements.requirementSetConstraints,
			this.hostRequirements.minimumVersions
		)) {
			await this.$router.push({
				name: 'RequirementsCheckFailed'
			});
			return;
		}
		await this.checkAuth();
		// Log analytics to show the taskpane was opened
		await AnalyticsEventLogger.logEvent(AnalyticJourneyId.RbpPanelShow);
		await AnalyticsEventLogger.flush();
	}
});
</script>

<template>
  <main class="full-height d-flex flex-column justify-content-center">
    <div class="container">
      <div class="row">
        <div class="col">
          <img
            id="egress-logo"
            :src="getLogoSrc"
            class="center"
            :alt="$t('common.egressLogoImgAlt')" />
          <div
            class="loader margin-top"
            role="alert"
            aria-live="assertive">
            <img
              class="loader__img center"
              alt="Spinning cogs"
              aria-hidden="true"
              :src="getLoaderSrc" />
            <h1 class="margin-top">
              {{ $t('common.loading') }}
            </h1>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
/* Any component dark mode styles go in here */
/*div#app.theme-dark {
	h1 {
		color: red;
	}
}*/

.loader {
	img {
		position: inherit;
	}
}

h1 {
	text-align: center;
}
</style>
