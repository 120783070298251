import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { taskpane, dateTimeFormats } from '@common/locales/en-GB';

Vue.use(VueI18n);

const VueI18nDateSet = async () => {
	let defaultLocaleData = taskpane;
	let defaultDateTimeFormats = dateTimeFormats;

	const localeDataSet = await import(`@common/locales/${process.env.VUE_APP_I18N_LOCALE}`);
	if (localeDataSet) {
		defaultLocaleData = localeDataSet.taskpane;
		defaultDateTimeFormats = localeDataSet.dateTimeFormats;
	}

	return new VueI18n({
		locale: process.env.VUE_APP_I18N_LOCALE,
		fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
		messages: <any>{ [process.env.VUE_APP_I18N_LOCALE as string]: defaultLocaleData },
		dateTimeFormats: <any>{ [process.env.VUE_APP_I18N_LOCALE as string]: defaultDateTimeFormats }
	});
};

// eslint-disable-next-line import/no-unused-modules
export default VueI18nDateSet().then((value) => value);
