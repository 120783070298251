/* eslint-disable no-await-in-loop */
import localforage from 'localforage';
import { StoreItemInterface } from '@common/interfaces';

export default class IndexedDbRepository<Type> {
	store : LocalForage;

	ttl?: number;

	name: string;

	constructor(name: string, ttl?: number) {
		this.store = localforage.createInstance({ name });
		this.ttl = ttl;
		this.name = name;
	}

	/**
	 * Drop the store instance created
	 * @returns {void}
	 */
	async dropStore(): Promise<void> {
		await this.store.dropInstance({ name: this.name });
	}

	/**
	 * Retrieves the value of the item with the given key
	 * @param {string} key The key of the item to retrieve
	 * @returns {object | StorageError}
	 */
	async get(key: string): Promise<Type | null> {
		await this.clearExpiredEntries();
		const result = await this.store.getItem<StoreItemInterface<Type>>(key);
		if (result !== null && result.value) {
			return result.value;
		}
		return null;
	}

	/**
	 * Updates the value of the item with the given key
	 * @param {string} key The key of the item to update
	 * @param {object} value The value to update the item with
	 * @param {number} ttl The time to live for this item in milliseconds
	 * @returns {void | StorageError}
	 */
	async update(key: string, value: Type | null, ttl?: number): Promise<void> {
		const now = (new Date()).getTime();
		const storeValue = <StoreItemInterface<Type>>{
			value
		};
		if (ttl) {
			storeValue.expiry = now + ttl;
		} else if (this.ttl) {
			storeValue.expiry = now + this.ttl;
		}
		await this.store.setItem<StoreItemInterface<Type>>(key, storeValue);
	}

	/**
     * Removes the item with the given key.
     * Does nothing if such an item doesn't exist
     * @param {string} key The key of the item to remove
     * @returns {void | StorageError}
     */
	async remove(key: string): Promise<void> {
		await this.store.removeItem(key);
		await this.clearExpiredEntries();
	}

	private async clearExpiredEntries() {
		const keys = await this.store.keys();
		const now = (new Date()).getTime();
		for (let i = 0; i < keys.length; i += 1) {
			const value = keys[i];
			const entry = await this.store.getItem<StoreItemInterface<Type>>(value);
			if (entry && entry.expiry && entry.expiry < now) {
				await this.store.removeItem(value);
			}
		}
	}
}
