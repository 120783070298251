/* eslint-disable no-mixed-spaces-and-tabs */
export default class Version {
	major?: number;

	minor?: number;

	revision?: number;

	build?: number;

	constructor(major?: number, minor?: number, revision?: number, build?: number) {
		this.major = major;
		this.minor = minor;
		this.revision = revision;
		this.build = build;
	}

	/**
	 * Parses a string in the format 'major.(minor).(revision).(build)
	 * @param version {string}
	 * @returns {Version}
	 */
	static fromString(version: string): Version {
		const parts = version.split('.');
		let major;
		let minor;
		let revision;
		let build;
		if (parts.length > 0) major = parseInt(parts[0], 10);
		if (parts.length > 1) minor = parseInt(parts[1], 10);
		if (parts.length > 2) revision = parseInt(parts[2], 10);
		if (parts.length > 3) build = parseInt(parts[3], 10);

		return new Version(major, minor, revision, build);
	}

	/**
	 * returns true if the version is greater or equal to the 'other' version, otherwise false
	 * @param other {Version}
	 * @returns {boolean}
	 */
	 atLeast(other: { major?: number; minor?: number; revision?: number; build?: number; }): boolean {
		if (this.major && other.major && this.major < other.major) return false;
		if (this.major && other.major && this.major > other.major) return true;
		if (this.minor && other.minor && this.minor < other.minor) return false;
		if (this.minor && other.minor && this.minor > other.minor) return true;
		if (this.revision && other.revision && this.revision < other.revision) return false;
		if (this.revision && other.revision && this.revision > other.revision) return true;
		if (this.build && other.build && this.build < other.build) return false;
		return true;
	}
}
