import ApiError from '@common/errors/apiError';
import AuthError from '@common/errors/authError';
import FetchError from '@common/errors/fetchError';
import AnalyticsEvent from '@common/analyticsEventLogger/analyticEvent';
import { buildPostRequestOption } from '@common/httpClient/buildRequestOptions';

/**
 * Calls the Analytics endpoint
 * @param authToken The authentication token for the current user
 * @param events analytic events to log
 */
const logAnalyticEvents = async (
	authToken: string,
	events: AnalyticsEvent[]
): Promise<void> => {
	try {
		const response = await fetch(
			'/api/v2/analytics/log',
			buildPostRequestOption({ events }, authToken)
		);

		// Handle failures
		if (!response.ok) {
			const responseData = await response?.json();
			switch (response.status) {
				case 401:
					throw new AuthError(
						'Logging analytics events failed. Users session has either expired or is invalid.',
						responseData?.localizationId,
						responseData?.code,
						responseData?.userEventId
					);
				default:
					throw new ApiError(
						`Logging analytics events failed. Server returned non-2xx status code. StatusCode:[${response.status}].`,
						responseData?.localizationId,
						responseData?.code,
						responseData?.userEventId
					);
			}
		}
	} catch (err) {
		if (err instanceof AuthError || err instanceof ApiError) {
			throw err;
		}
		throw new FetchError('Fetch request could not be sent', 'api.errors.fetchFailed');
	}
};

export default logAnalyticEvents;
