/* eslint-disable no-bitwise */
/* eslint-disable class-methods-use-this */
import { getEsiToken } from '@common/token';
import { getUserState } from '@common/utils/clientState';
import AnalyticJourneyId from '@common/enums/analyticJourneyId';
import AnalyticsEvent from '@common/analyticsEventLogger/analyticEvent';
import logAnalyticEvents from '@common/httpClient/analytics/client';
import { OfficeProxy } from '@egress/officejs-proxy';
import { AttachmentDetailsInterface, RecipientInterface } from '@common/interfaces';
import { RbpAuditOptions } from '@common/enums';

class AnalyticsEventLogger {
	queuedEvents: AnalyticsEvent[];

	constructor() {
		this.queuedEvents = [];
	}

	async logEvent(journeyId: AnalyticJourneyId): Promise<AnalyticsEvent> {
		const proxy = new OfficeProxy();
		const restId = await proxy.getItemRestIdAsync();
		const newEvent = new AnalyticsEvent(journeyId, restId);
		this.queuedEvents.push(newEvent);

		return newEvent;
	}

	async flush(): Promise<void> {
		try {
			// check if policy is configured for analytics logging
			const { auditOptions } = await getUserState();

			if ((RbpAuditOptions.DlpLog & auditOptions) === RbpAuditOptions.DlpLog
			|| (RbpAuditOptions.DLPVebose & auditOptions) === RbpAuditOptions.DLPVebose) {
				const authToken = await getEsiToken();
				if (authToken && this.queuedEvents.length > 0) {
					AnalyticsEventLogger.log(authToken, [...this.queuedEvents]);
					this.queuedEvents.length = 0; // reset the initial queue
				}
			} else {
				// reset the queue, analytics logging not configured
				this.queuedEvents.length = 0;
			}
		} catch (err) {
			// Best effort send, events will be sent on subsequent call
		}
	}

	getAttachmentName(attachments: AttachmentDetailsInterface[]): string {
		let val: string[] = [];
		if (attachments) {
			val = attachments?.map((argument) => argument.fileName);
		}

		return val.join(';');
	}

	getRecepientEmail(recipients: RecipientInterface[]): string {
		const val = recipients?.map((recipient) => recipient.email);
		return val.join(';');
	}

	static log(authToken: string, events: AnalyticsEvent[]): void {
		// Upload the events to API endpoint
		logAnalyticEvents(authToken, events);
	}
}

export default AnalyticsEventLogger;
