<script lang='ts'>
import Vue from 'vue';
import { CapturedMatchStructure } from '@common/interfaces';
import EdsTable from '@/components/eds/Table.vue';

export default Vue.extend({
	name: 'CapturedMatchesTable',
	components: {
		EdsTable
	},
	props: {
		matches: {
			type: Array,
			required: true
		},
		classes: {
			type: String,
			required: false,
			default: ''
		}
	},
	computed: {
		getClasses(): string {
			return this.classes;
		},
		getTableItems(): Object[] {
			const capturedMatches = this.matches as CapturedMatchStructure[];
			const tableItems: Object[] = [];
			capturedMatches.forEach((file) => {
				if (file.matches && file.fileName) {
					Object.keys(file.matches).forEach((category) => {
						file.matches![category].forEach((word) => {
							if (!category.startsWith('#')) {
								tableItems.push({
									Match: word.plainText, // eg: 'ahole'
									Category: category, // eg: 'profanity'
									Location: this.getFriendlyLocationName(file.fileName!) // eg: 'Subject/Email Body'
								});
							}
						});
					});
				}
			});
			return tableItems;
		}
	},
	methods: {
		getFriendlyLocationName(name: string): string {
			switch (name.toLocaleLowerCase()) {
			case '@!MessageBody!@.txt'.toLocaleLowerCase():
				return this.$t('dlp.matchLocationFriendlyNames.messageBody').toString();
			case '@!Addresses!@.txt'.toLocaleLowerCase():
				return this.$t('dlp.matchLocationFriendlyNames.addresses').toString();
			default:
				return name;
			}
		}
	}
});
</script>

<template>
  <div :class="getClasses">
    <EdsTable id="captured-matches-table" :items="this.getTableItems" />
  </div>
</template>
