import { OfficeProxy } from '@egress/officejs-proxy';
import { SWITCH_HEADER } from '@common/constants';
// eslint-disable-next-line import/no-self-import
import * as switchHeaderFuncs from '@common/utils/switchHeader';
import { ExtendedInternetHeaders } from '@common/interfaces';
import performanceLogger from '@common/performanceLogger';

interface SetSwitchHeaderOptionsInterface {
	[index: string]: string | string[] | undefined;
	switchId?: string,
	labelId?: string,
	software?: string,
	tags?: string[],
	processed?: string
}

function createSwitchHeaderValue(
	keys : string[],
	values : { [key: string]: string | string [] | undefined }
) {
	let header = '';
	keys.forEach((key) => {
		let value = values[key];
		if (value) {
			if (key === 'tags' && Array.isArray(value)) {
				value = value.join(';');
			}
			header = `${header}${key}="${value}";`;
		}
	});

	return header;
}

/**
 * Set or update values on the x-$switch internet header
 * @param headerElements The list of elements to add/update.
 * Allowed header elements are switchID, labelID, software, tags.
 * Elements previously committed to the header will be preserved.
 * @returns {Promise<void>}
 */
async function setSwitchHeader(
	headerElements: SetSwitchHeaderOptionsInterface
): Promise<void> {
	const timer = performanceLogger.startNew('utils.setSwitchHeader');
	const allowedElementNames = ['switchID', 'labelID', 'software', 'tags', 'processed'];
	const partsToUpdate: { [key: string] : string | string[] | undefined } = { };
	const existingParts: { [key: string] : string | string[] | undefined } = { };
	const proxy = new OfficeProxy();
	// eslint-disable-next-line max-len
	const headers = <ExtendedInternetHeaders> await proxy.getItemInternetHeadersAsync([SWITCH_HEADER]);
	const switchHeader = headers?.[SWITCH_HEADER] ?? '';

	allowedElementNames.forEach((validElemName) => {
		const matchingElement = Object.keys(headerElements).find(
			(key) => key.toLowerCase() === validElemName.toLowerCase()
		);

		if (matchingElement) {
			partsToUpdate[validElemName] = headerElements[matchingElement];
		} else {
			// Check if an existing item already exists in the header and preserve that if so.
			const existingValue = switchHeaderFuncs.getElementFromSwitchHeader(
				switchHeader,
				validElemName
			);

			if (existingValue) {
				existingParts[validElemName] = existingValue;
			}
		}
	});

	const keys = Object.keys(partsToUpdate);
	if (!keys.length) {
		throw new Error('Unable to create switch header. no header elements were provided.');
	}

	// eslint-disable-next-line max-len
	const combinedParts: { [key: string] : string | string[] | undefined } = { ...partsToUpdate, ...existingParts };
	const combinedPartsKeys = Object.keys(combinedParts);

	// https://wiki.egress.com/display/CS/Understanding+Message+Headers
	const header = createSwitchHeaderValue(combinedPartsKeys, combinedParts);

	await proxy.setItemInternetHeadersAsync({
		[SWITCH_HEADER]: header
	});
	timer.stop();
}

function getElementFromSwitchHeader(headerString?: string, elementName?: string): string | null {
	if (!headerString || !elementName) return null;

	// Ugly looking regex to split on semicolon UNLESS it appears within quotes (eg. in the taglist)
	const parts = headerString.split(/;(?=(?:[^"]*"[^"]*")*[^"]*$)/g);
	const foundPart = parts.find((p) => p.toLowerCase().includes(elementName.toLowerCase()));
	if (!foundPart) return null;

	return foundPart.split('=')[1].replace(/"/g, '');
}

export {
	setSwitchHeader,
	getElementFromSwitchHeader
};
