import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const appInformationModule = {
	state: {
		appVersion: '',
		diagnosticInformation: null,
		hostLocation: '',
		enableExperimentalFeatures: false,
		blockOnSendWhenError: false,
		onSendErrorTags: []
	},
	mutations,
	actions,
	getters
};

export default appInformationModule;
