import { OfficeProxy } from '@egress/officejs-proxy';
import { getMessageMimeContentAsync, getMessageAttachmentContentAsync } from '@common/httpClient/outlook/client';
import { MimeAttachment } from '@common/interfaces';

const updateMessage = () => new Promise((resolve, reject) => {
	Office.context.mailbox.item?.saveAsync(async (asyncResult) => {
		if (asyncResult.status === Office.AsyncResultStatus.Failed) {
			reject(asyncResult.error);
		} else {
			resolve(asyncResult.value);
		}
	});
});

const getMessageBodyAsync = async (): Promise<string> => {
	try {
		const proxy = new OfficeProxy();
		const restUrl = proxy.getRestUrl();
		const restId = await proxy.getItemRestIdAsync();
		const authToken = await proxy.getRestTokenAsync();

		// Save message details to ensure getting latest copy of message
		await updateMessage();

		// formulate the messageUrl query to retrieve only message body
		const messageUrl = `${restUrl}/v2.0/me/messages/${restId}?$select=Body`;
		const message = await getMessageMimeContentAsync(messageUrl, authToken, 'outlook.body-content-type="text"');

		return message.Body.Content;
	} catch (error) {
		// Dont throw error, best effort approach
		return '';
	}
};

const getMessageBodyOfTypeAsync = async (bodyType: string): Promise<string> => {
	// allow to fail - LFT fails closed
	const proxy = new OfficeProxy();
	const restUrl = proxy.getRestUrl();
	const restId = await proxy.getItemRestIdAsync();
	const authToken = await proxy.getRestTokenAsync();

	// Save message details to ensure getting latest copy of message
	await updateMessage();

	// formulate the messageUrl query to retrieve only message body
	const messageUrl = `${restUrl}/v2.0/me/messages/${restId}?$select=Body`;
	const message = await getMessageMimeContentAsync(messageUrl, authToken, bodyType);

	return message.Body.Content;
};

const getMessageAttachmentsAsync = async (): Promise<MimeAttachment[]> => {
	// allow to fail - LFT fails closed
	const proxy = new OfficeProxy();
	const restUrl = proxy.getRestUrl();
	const restId = await proxy.getItemRestIdAsync();
	const authToken = await proxy.getRestTokenAsync();

	// Save message details to ensure getting latest copy of message
	await updateMessage();

	// formulate the messageUrl query to retrieve only message body
	const messageUrl = `${restUrl}/v2.0/me/messages/${restId}/attachments`;
	const message = await getMessageAttachmentContentAsync(messageUrl, authToken);

	return message.value.filter((attachment: any) => attachment.IsInline).map((attachment: any) => ({
		id: attachment.ContentId,
		type: attachment.ContentType,
		name: attachment.Name,
		content: attachment.ContentBytes,
		size: attachment.Size
	}));
};

const requiresOutlookRest = async (): Promise<boolean> => {
	const proxy = new OfficeProxy();
	const clientInfo = OfficeProxy.getClientInformation();
	const composeType = await proxy.getItemComposeTypeAsync();
	const isNewEmail = (composeType.toLowerCase() === 'newmail');
	return ((clientInfo.platform.toLowerCase() === 'mac' || ['outlookwebapp', 'newoutlookwindows'].includes(clientInfo.hostName.toLowerCase())) && !isNewEmail);
};

export {
	getMessageBodyAsync,
	getMessageBodyOfTypeAsync,
	getMessageAttachmentsAsync,
	requiresOutlookRest,
	updateMessage
};
