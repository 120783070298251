import TokenDetail from '@common/token/tokenDetail';
import IndexedDbRepository from '@common/dbStorage/indexedDbStore';

/* eslint-disable no-undef */
export default class TokenStore {
	store: IndexedDbRepository<TokenDetail>;

	/**
	 * Create an instance of the TokenStore class
	 * @param {string | null} storeName Provide a name to give this token store.
	 */
	constructor(storeName: string | null = null) {
		this.store = new IndexedDbRepository(storeName ?? 'token-store');
	}

	/**
	 * Retrieves the value of the token with the given name
	 * @param {string} tokenName The name of the token to retrieve
	 * @returns {TokenDetail | StorageError}
	 */
	async getToken(tokenName: string): Promise<TokenDetail | null> {
		const foundToken = await this.store.get(tokenName);
		if (!foundToken) return null;

		return new TokenDetail(foundToken.name, foundToken.data, foundToken.expiresAtUtc);
	}

	/**
	 * Updates the value of the token with the given name
	 * @param {TokenDetail} tokenValue The value to update the token with
	 * @returns {void | StorageError}
	 */
	async updateToken(tokenValue: TokenDetail): Promise<void> {
		await this.store.update(tokenValue.name, tokenValue);
	}

	/**
	 * Removes the token with the given name from the store.
	 * Does nothing if such a token doesn't exist
	 * @param {string} tokenName The name of the token to remove
	 * @returns {void | StorageError}
	 */
	async removeToken(tokenName: string): Promise<void> {
		await this.store.remove(tokenName);
	}

	/**
	 * Drops the instance of the created store.
	 * @returns {void | StorageError}
	 */
	async dropStore(): Promise<void> {
		await this.store.dropStore();
	}
}
