import IndexedDbRepository from '@common/dbStorage/indexedDbStore';

export default class OriginalRecipientsStore {
	store: IndexedDbRepository<string>;

	/**
	 * Create an instance of the OriginalRecipientsStore class
	 * @param {string | null} storeName Provide a name to give this store.
	 */
	constructor(storeName: string | null = null) {
		this.store = new IndexedDbRepository(storeName ?? 'original-recipients-store', 604800000);
	}

	/**
	 * Retrieves the value of the originalRecipients with the given restId
	 * @param {string} restId The restId of the message to retrieve
	 * @returns {object | StorageError}
	 */
	async getOriginalRecipients(restId: string): Promise<string | null> {
		const found = await this.store.get(restId);
		if (!found) return null;
		return found;
	}

	/**
	 * Updates the value of the originalRecipients with the given restId
	 * @param restId The restId of the message item to update
	 * @param {object} originalRecipients The value to update the originalRecipients with
	 * @returns {void | StorageError}
	 */
	async updateOriginalRecipientInfo(restId:string, originalRecipients: string): Promise<void> {
		await this.store.update(restId, originalRecipients);
	}

	/**
	 * Removes the originalRecipients with the given restId from the store.
	 * Does nothing if such an originalRecipients doesn't exist
	 * @returns {void | StorageError}
	 */
	async removeOriginalRecipientsInfo(restId: string): Promise<void> {
		await this.store.remove(restId);
	}

	/**
	 * Drops the instance of the created store.
	 * @returns {void | StorageError}
	 */
	async dropStore(): Promise<void> {
		await this.store.dropStore();
	}
}
