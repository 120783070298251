<script lang='ts'>
import Vue from 'vue';
import { OfficeProxy } from '@egress/officejs-proxy';
import { DIALOG_CLOSE, DIALOG_TAGSELECTED } from '@common/constants';
import { Base64 } from 'js-base64';
import {
	CapturedMatchStructure,
	RequestTagOptionStructure,
	RequestTagStructure
} from '@common/interfaces';
import EdsButton from '@/components/eds/Button.vue';
import EdsSelect from '@/components/eds/Select.vue';
import EdsInput from '@/components/eds/Input.vue';
import CapturedMatchesTable from '@/components/dem/CapturedMatchesTable.vue';
import egressLogoLight from '@/assets/img/eds-light-logo.svg';
import egressLogoDark from '@/assets/img/eds-dark-logo.svg';
import PromptCountdownTimer from '@/views/dlpPrompts/PromptCountdownTimer.vue';
import { RequestTagPromptInputType, theme } from '@common/enums';
import goToExternalLink from '@common/utils/externalLink';
import { EdsSelectEventOutput } from '@/interfaces';

export default Vue.extend({
	name: 'RequestTagPrompt',
	data() {
		return {
			promptInputTypes: RequestTagPromptInputType,
			questionField: '',
			answerField: '',
			loadError: false,
			dataError: false,
			countdownTimeRemaining: parseInt(this.countdown, 10)
		};
	},
	props: {
		countdown: {
			type: String,
			required: true
		},
		rtds: {
			type: String,
			default: null
		},
		theme: {
			type: String,
			required: true
		}
	},
	components: {
		PromptCountdownTimer,
		EdsButton,
		EdsInput,
		EdsSelect,
		CapturedMatchesTable
	},
	methods: {
		onClickOverride(event: any) {
			if (event.target.tagName.toLowerCase() === 'a') {
				goToExternalLink(event.target.href);
			}
		},
		async setupPrompt(): Promise<void> {
			if (this.loadError) {
				return;
			}

			this.questionField = this.requestTagQuestion ?? '';
			// Use answer from button option if no text input field displayed in prompt
			// else use expected answer supplied by policy to match text field value (ojs ticket: 115927)
			this.answerField = this.currentPromptInputType === RequestTagPromptInputType.Button
				&& this.requestTagDetails?.options
				? this.requestTagDetails?.options[0].answer : this.requestTagDefaultAnswer ?? '';
		},
		handleRequestTagPromptResponse(event: Event): void {
			if (event) {
				const element = event.target as HTMLElement;
				const { answer } = JSON.parse(element.getAttribute(('data-attributes')) ?? '{}');
				if (answer && answer.length) {
					this.answerField = answer;
				}
			}
			if (this.answerIsValid) {
				OfficeProxy.messageParent(JSON.stringify({
					type: DIALOG_TAGSELECTED,
					selectedValue: {
						tagName: this.requestTagTagName,
						tagValue: this.answerField
					}
				}));
			}
		},
		closePrompt(): void {
			OfficeProxy.messageParent(JSON.stringify({
				type: DIALOG_CLOSE
			}));
		},
		handleTagDropdownChange(value: EdsSelectEventOutput): void {
			this.answerField = value.id;
		}
	},
	computed: {
		requestTagDetails(): RequestTagStructure {
			if (this.rtds.length) {
				return JSON.parse(Base64.decode(this.rtds));
			}
			return {};
		},
		getEgressLogoSrc(): any {
			return (this.theme === theme.Dark)
				? egressLogoDark
				: egressLogoLight;
		},
		getPromptTitle(): any {
			return this.requestTagTitle ?? `<p>${this.$t('requestTagPrompt.defaultTitle')}</p>`;
		},
		answerIsValid(): boolean {
			if (this.requestTagValidationPattern && this.requestTagValidationPattern.trim() !== '') {
				const regex = new RegExp(this.requestTagValidationPattern);
				return regex.test(this.answerField);
			}
			return (this.answerField !== '');
		},
		questionIsValid(): boolean {
			return !!(this.requestTagQuestion || this.questionField !== '');
		},
		canSubmitPrompt(): boolean {
			return this.answerIsValid && this.questionIsValid;
		},
		capturedMatches(): CapturedMatchStructure[] | undefined {
			return this.requestTagDetails?.capturedMatches ?? [];
		},
		requestTagTagName(): string | undefined {
			return this.requestTagDetails?.tagName?.includes('*')
				? this.requestTagDetails.tagName.replaceAll('*', this.questionField) : this.requestTagDetails.tagName;
		},
		requestTagValidationPattern(): string | undefined {
			return this.requestTagDetails?.validationPattern;
		},
		requestTagQuestion(): string | undefined {
			return this.requestTagDetails?.question === '*'
				? undefined : this.requestTagDetails.question;
		},
		requestTagTitle(): string | undefined {
			return this.requestTagDetails?.title;
		},
		requestTagHint(): string | undefined {
			return this.requestTagDetails?.hint;
		},
		requestTagDefaultAnswer(): string | undefined {
			return this.requestTagDetails?.defaultAnswer;
		},
		requestTagOptions(): RequestTagOptionStructure[] {
			return this.requestTagDetails?.options ?? [];
		},
		requestTagOptionsDropDownContents(): Array<any> {
			if (this.requestTagOptions?.length < 1) {
				return [];
			}
			const firstOption = {
				name: 'Please select a tag from the list',
				id: '',
				hidden: true
			};

			const mainOptions = this.requestTagOptions.map((option) => ({
				name: `${option.title} (${option.subtitle})`,
				id: option.answer
			}));

			return [firstOption, ...mainOptions];
		},
		shouldShowDefaultConfirmButton(): boolean {
			return [this.promptInputTypes.Freetext, this.promptInputTypes.Dropdown]
				.includes(this.currentPromptInputType);
		},
		defaultConfirmButtonText(): string {
			if (this.promptInputTypes.Dropdown) {
				return this.$t('common.ok') as string;
			}
			return this.$t('common.send') as string;
		},
		currentPromptInputType(): RequestTagPromptInputType {
			if (this.requestTagOptions?.length === 1) {
				return RequestTagPromptInputType.Button;
			}
			if (this.requestTagOptions?.length > 1) {
				return RequestTagPromptInputType.Dropdown;
			}
			return RequestTagPromptInputType.Freetext;
		},
		hasDataError(): boolean {
			return this.dataError;
		},
		hasValidationError(): boolean {
			return (this.questionField?.length > 0 && !this.questionIsValid)
				|| (this.answerField?.length > 0 && !this.answerIsValid);
		},
		validationHint(): string {
			return this.requestTagHint ?? this.$t('requestTagPrompt.answerValidationError').toString();
		}
	},
	mounted(): void {
		if (!this.rtds || this.rtds === '') {
			this.loadError = true;
		}
		this.setupPrompt();
	}
});
</script>

<template>
  <div class="container">
    <div class="row">
      <div id="request-tag-prompt" class="dlp-prompt">
        <PromptCountdownTimer
          :timeRemaining="this.countdownTimeRemaining"
          @timerExpired="this.closePrompt" />
        <div v-if="!loadError">
          <div>
            <img
              :src="getEgressLogoSrc"
              alt="egressIcon"
              aria-hidden="true"
            >
          </div>
          <div class="dlp-title pt-3" v-html="this.getPromptTitle" />
          <div v-if="this.requestTagQuestion">
            <div
              class="py-3 dlp-info"
              style="white-space: pre-wrap"
              v-html="this.requestTagQuestion"
              v-on:click.prevent="onClickOverride($event)"
              v-on:keydown="onClickOverride($event)"
            />
          </div>
          <fieldset>
            <EdsInput
              class="py-3"
              v-if="!this.requestTagQuestion"
              v-model="questionField"
              id="tag-question"
              label="Question: "
              :allowAutocomplete="false"
              required
              placeholder="Please enter your question here"
            />
            <EdsInput
              class="py-3"
              v-if="this.currentPromptInputType === promptInputTypes.Freetext"
              v-model="answerField"
              id="tag-answer"
              label="Answer: "
              :allowAutocomplete="false"
              required
              placeholder="Type your answer here"
            />
            <EdsSelect
              class="py-3"
              v-if="this.currentPromptInputType === promptInputTypes.Dropdown"
              v-model.lazy="answerField"
              @input="handleTagDropdownChange"
              @change="handleTagDropdownChange"
              id="tag-options-select"
              label="Answer: "
              :options="this.requestTagOptionsDropDownContents"
            />
            <CapturedMatchesTable
              class="py-3"
              v-if="this.capturedMatches.length"
              :matches="this.capturedMatches" />
            <div class="base mt-4 mb-4">
              <div class="pt-2">
                <p
                  v-if="hasValidationError"
                  class="p-2 eds-form-error-text d-block mb-1">
                  {{ this.validationHint }}
                </p>
                <p
                  id="input-field-error"
                  v-if="hasDataError"
                  class="eds-form-error-text d-block mb-1">
                  {{ $t('requestTagPrompt.missingDataError') }}
                </p>
              </div>
              <div class="controls">
                <EdsButton
                  v-if="this.shouldShowDefaultConfirmButton"
                  id="btn-send-ok"
                  class="mr-3"
                  buttonType="prime"
                  type="submit"
                  :disabled="!canSubmitPrompt"
                  @click="handleRequestTagPromptResponse">
                  {{ this.defaultConfirmButtonText }}
                </EdsButton>
                <div
                  id="dynamic-button"
                  v-if="this.currentPromptInputType === promptInputTypes.Button">
                  <EdsButton
                    v-for="(item) in this.requestTagOptions"
                    v-bind:key="item.answer"
                    id="btn-send-ok"
                    class="mr-3"
                    buttonType="prime"
                    :dataAttributes="{ answer: item.answer }"
                    type="submit"
                    :disabled="!canSubmitPrompt"
                    @click="handleRequestTagPromptResponse">
                    {{ item.title }}
                  </EdsButton>
                </div>
                <EdsButton
                  id="btn-send-cancel"
                  buttonType="secondary"
                  @click="closePrompt">
                  {{ $t('common.cancel') }}
                </EdsButton>
              </div>
            </div>
          </fieldset>
        </div>
        <div v-if="loadError">
          <p
            id="load-error"
            class="eds-form-error-text d-block m2">
            {{ this.$t('prompt.loadError') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
