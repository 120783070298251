import IndexedDbRepository from '@common/dbStorage/indexedDbStore';
import { LOCALSTORAGE_APP_CONFIG_STORE } from '@common/constants';
import hash from 'object-hash';
import { ConfigurationInterface } from '@common/interfaces';

export default class AppConfigStore {
	store: IndexedDbRepository<ConfigurationInterface>;

	hash: string = hash(LOCALSTORAGE_APP_CONFIG_STORE);

	/**
	 * Create an instance of the ConfigStore class
	 * @param {string | null} storeName Provide a name to give this config store.
	 */
	constructor(storeName: string | null = null) {
		this.store = new IndexedDbRepository(storeName ?? 'app-config-store', 86400000);
	}

	/**
	 * Retrieves the value of the config with the given hash
	 * @returns {object | StorageError}
	 */
	async getConfig(): Promise<ConfigurationInterface | null> {
		const found = await this.store.get(this.hash);
		if (!found) return null;
		return found;
	}

	/**
	 * Updates the value of the config with the given hash
	 * @param {object} config The value to update the config with
	 * @returns {void | StorageError}
	 */
	async updateConfig(config: ConfigurationInterface): Promise<void> {
		await this.store.update(this.hash, config);
	}

	/**
	 * Removes the config with the given hash from the store.
	 * Does nothing if such an config doesn't exist
	 * @returns {void | StorageError}
	 */
	async removeConfig(): Promise<void> {
		await this.store.remove(this.hash);
	}

	/**
	 * Drops the instance of the created store.
	 * @returns {void | StorageError}
	 */
	async dropStore(): Promise<void> {
		await this.store.dropStore();
	}
}
